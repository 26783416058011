/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { GA_EVENT_CATEGORY } from 'lib/utils/CONSTANTS';

const initialState = {
  appState: {},
  isCrawler: false,
  hreflangData: null,
  deviceType: '',
  showMembership: false,
  googleAnalytics: {
    newsLetterEventCategory: GA_EVENT_CATEGORY.other,
  },
  isInIframe: false,
  agentLocationInfo: null,
  leadCategoryId: null,
};

export const globalDataSlice = createSlice({
  name: 'globalData',
  initialState,
  reducers: {
    setAppData: (state, action) => {
      state.appState = action.payload;
    },
    setIsCrawler: (state, action) => {
      state.isCrawler = action.payload;
    },
    setIsSSR: (state, action) => {
      state.isSSR = action.payload;
    },
    setHrefLangData: (state, action) => {
      state.hreflangData = action.payload;
    },
    setDeviceType: (state, action) => {
      state.deviceType = action.payload;
    },
    setShowMembership: (state, action) => {
      state.showMembership = action.payload;
    },
    setGoogleAnalytics: (state, action) => {
      state.googleAnalytics = action.payload;
    },
    setIsInIframe: (state, action) => {
      state.isInIframe = action.payload;
    },
    setAgentLocationInfo: (state, action) => {
      state.agentLocationInfo = action.payload;
    },
    setLeadCategoryId: (state, action) => {
      state.leadCategoryId = action.payload;
    },
    resetGlobalDataSlice: () => initialState,
  },
});

export const {
  setAppData,
  setIsCrawler,
  setIsSSR,
  setHrefLangData,
  resetGlobalDataSlice,
  setDeviceType,
  setShowMembership,
  setGoogleAnalytics,
  setIsInIframe,
  setAgentLocationInfo,
  setLeadCategoryId,
} = globalDataSlice.actions;

export default globalDataSlice.reducer;
