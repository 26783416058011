/* eslint-disable global-require */
import axios from 'axios';
import { mapNamedLocaleToLocale } from 'lib/data/localesMap';
import removeTrailingSlash from 'lib/utils/removeTraillingSlash';

let Router;
if (typeof window !== 'undefined') {
  const { default: router } = require('next/router');
  Router = router;
}
const { envVars } = require('next/config').default().publicRuntimeConfig;

const { API_URL } = envVars;

axios.interceptors.request.use(
  (config) => {
    let acceptLangHeader = {};
    try {
      acceptLangHeader = {
        'Accept-Language':
          mapNamedLocaleToLocale[Router?.locale] ||
          Router?.locale ||
          config.headers['Accept-Language'],
      };
    } catch {
      acceptLangHeader = {
        'Accept-Language': config.headers['Accept-Language'],
      };
    }
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const pathName = window.location.pathname;
      // eslint-disable-next-line no-param-reassign
      config.params = {
        preferred_seo_identifer: decodeURIComponent(
          removeTrailingSlash(
            Router?.locale === 'zh' ? `/zh${pathName}` : pathName,
          ),
        ),
        cache: urlParams.get('cache'),
        ...config.params,
      };
    }
    return {
      baseURL: API_URL,
      ...config,
      headers: {
        ...config.headers,
        ...acceptLangHeader,
      },
    };
  },
  (err) => Promise.reject(err),
);

export default axios;

export const API = {
  hello: '/hello',
  seoValues: '/page_seo_values',
  authenticityToken: '/authenticity_token',
  countries: {
    get: '/countries',
    change: '/countries/change',
  },
  flashMessage: '/flash_message',
  currencies: {
    index: '/currencies',
    change: 'currencies/change',
  },
  owner: {
    dashboard: '/user/dashboard',
  },
  tours: {
    home: '/tours/home',
    index: '/tours',
    search_widget: '/tours/search_widget',
    withId: '/tours/{id}',
    review: '/tours/{tour_id}/my_review',
    reviewVote: '/reviews/{id}/vote',
    bookingDetails: '/tours/{tour_id}/booking_details',
    bookTour: '/tours/{tour_id}/booking_details',
    reviews: '/tours/{identifier}/reviews',
  },
  offRoadLanding: '/offroad_tours',
  location: '/locations/{id}',
  users: {
    forgotPassword: '/users/password',
    signIn: '/auth/sign_in',
    signOut: '/auth/sign_out',
    signUp: '/auth/sign_up',
    sendEmail: '/users/send_email',
    sendSMS: '/users/send_sms',
    resendSMS: '/users/resend_sms',
    verifyPhone: '/users/verify_phone',
    verifyEmail: '/users/verify_email',
    thirdPartyAuth: '/users/third_party_authentication',
    signViaOTP: '/users/sign_via_otp',
    signViaAccessToken: '/auth/sign_via_access_token',
    thirdParty: '/auth/third_party_authentication',
  },
  vrs: {
    lookup: '/vrs/lookup',
    associate:
      '/vehicle_reservations/{identifier}/checkin/associate-reservation',
    verification:
      '/vehicle_reservations/{identifier}/checkin/identity-verification',
    postDriverLicense: '/vehicle_reservations/{identifier}/driver_license',
    deleteDriverLicense:
      '/vehicle_reservations/{identifier}/driver_license/{id}',
    checkin: '/vehicle_reservations/{identifier}/checkin',
    getDriverLicenseOCR:
      '/vehicle_reservations/{identifier}/driver_license/{id}/ocr_fields',
    pickupContract: '/vrs/{identifier}/pickup_contract',
    savePickupSignature: '/vrs/{identifier}/save_pickup_signature',
    dropoffReceipt: '/vrs/{identifier}/dropoff_receipt',
    saveDropoffSignature: '/vrs/{identifier}/save_dropoff_signature',
  },
  contactUs: '/contact_us',
  layout: '/layout',
  newslettersSubscribe: '/newsletters_subscribe',

  siteIndex: {
    siteIndex: '/siteindex',
    filteredSiteIndex: '/siteindex/{place}',
  },
  conversations: '/conversations',
  vehicles: {
    vehicle: '/vehicles/{id}',
    reviews: '/vehicles/{id}/reviews',
    reservationsIntervals: '/vehicles/{id}/reservations_intervals',
    bookingDetails: '/vehicles/{vehicle_id}/booking_details',
    vehicleReservations: '/vehicle_reservations',
  },
  bookingsManagement: {
    ownerList: '/owner/vehicle_reservations',
    ownerFilters: '/owner/vehicle_reservations/filters',
    tripsList: '/user/vehicle_reservations',
    sendCheckInReminder:
      '/vehicle_reservations/{vehicle_reservation_id}/send_check_in_reminder',
    sendConfirmationReminder:
      '/vehicle_reservations/{vehicle_reservation_id}/send_confirmation_reminder',
  },

  confirmation: '/vehicle_reservations/{vehicle_reservation_id}/confirmation',

  regions: '/regions',
  geographies: '/geographies',
  payments: {
    creditCards: '/credit_cards',
  },
  bookings: '/owner/vehicle_reservations',
  trips: '/user/vehicle_reservations',
  reservationDetails: '/vehicle_reservations/{id}',

  checkinConfirmation:
    '/vehicle_reservations/{identifier}/checkin_confirmation',

  memberships: {
    index: '/memberships',
    withId: '/memberships/{id}',
    referralCode: '/memberships/referral_code',
    searchForUser: '/memberships/search_for_user',
    checkoutGet: '/memberships/{id}/checkout',
    checkoutPost: '/memberships/{id}/checkout',
    promotedPlans: '/memberships/promoted_plans',
    upgradeGet: '/memberships/{id}/upgrade',
    upgradePost: '/memberships/{id}/upgrade',
  },
  user: {
    membership: '/user/membership',
    reactivateMembership: '/user/membership/reactivate',
    disableRenewal: '/user/membership/disable_renewal',
    updateRenewal: '/user/membership/update_renewal',
    creditCardsList: '/user/credit_cards',
    addUserCreditCard: '/user/credit_cards',
    yearlyPlan: '/user/membership/yearly_plan',
    switchToYearly: '/user/membership/switch_to_yearly',
    creditCardsBillingInfo: '/user/credit_cards/new',
    deleteCreditCard: '/user/credit_cards/{id}',
    automateCreditCard: '/user/credit_cards/automatic_billing_credit_card',
    makeMoney: '/user/make_money',
    rewards: '/user/rewards',
    resumableReservations: '/user/resumable_reservations',
    deactivate: '/users/deactivate',
  },
  giftCards: {
    design: '/gift_card_designs',
    post: '/gift_cards',
  },
  reservations: {
    checkout: '/reservations/{reservation_id}/checkout',
    riderNames: '/reservations/{reservation_id}/rider_names',
    changeRiderNames:
      '/reservations/{reservation_id}/checkout/membership/change_riders_names',
    skipRiderNames: '/reservations/{reservation_id}/skip_rider_names',
    extras: '/reservations/{reservation_id}/extras',
    confirmation: '/reservations/{reservation_id}/confirmation',
    delete: '/reservations/{reservation_id}',
    cancel: '/reservations/{reservation_id}/cancel',
    saveNote: '/reservations/{reservation_id}/save_note',
    saveLead: '/reservations/{reservation_id}/save_lead',
    getCustomer: '/reservations/{reservation_id}/get_customer',
    riders: '/reservations/{reservation_id}/riders',
    validate: '/reservations/{reservation_id}/store_credits/validate',
    creditCards: '/reservations/{reservation_id}/credit_cards',
    public_confirmation: '/reservations/{reservation_id}/public_confirmation',
    payments: {
      byCreditCard: '/reservations/{reservation_id}/payments/by_credit_card',
      saveCreditCard: '/reservations/{reservation_id}/credit_cards',
      byStoreCredit: '/reservations/{reservation_id}/payments/by_store_credit',
      byCheck: '/reservations/{reservation_id}/payments/by_check',
      byCash: '/reservations/{reservation_id}/payments/by_cash',
      byWireTransfer:
        '/reservations/{reservation_id}/payments/by_wire_transfer',
      byMembershipCreidts:
        '/reservations/{reservation_id}/payments/by_membership_credits',
      byRewardCredit:
        '/reservations/{reservation_id}/payments/by_reward_credit',
      validate: '/reservations/{reservation_id}/payments/validate',
      externalPayment:
        '/reservations/{reservation_id}/payments/external_payment',
      initiateExternalPayment:
        '/reservations/{reservation_id}/payments/initiate_external_payment',
      adjustPaidCredits:
        '/reservations/{reservation_id}/payments/adjust_paid_credits',
    },
    optoinalUpsells: '/reservations/{reservation_id}/optional_upsells',
    create: '/reservations',
    newEdit: '/reservations/{reservation_id}/new_edit',
    initiateEdit: '/reservations/{id}/initiate_edit',
    applyPromoCode: '/reservations/{reservation_id}/apply_promo_code',
    motorcycles: '/reservations/{reservation_id}/motorcycles',
    vipDiscount: '/reservations/{reservation_id}/vip_discount',
    riderVehicles: {
      id: '/reservations/{reservation_id}/rider_vehicles/{id}',
    },
    membership: {
      plans: '/reservations/{reservation_id}/membership/plans',
      apply: '/reservations/{reservation_id}/membership/apply',
    },
    resumeReservation: '/reservations/{reservation_identifier}/resume',
  },
  creditCards: '/credit_cards',
  reservation: '/reservation',
  locations: '/locations',
  rentalWidget: '/rental_widget',
  events: {
    index: '/events',
    id: '/events/{id}',
    reviewVote: '/reviews/{id}/vote',
    review: '/events/{id}/review',
    reviews: '/events/{identifier}/reviews',
  },
  detectSlugs: '/detect_slugs',
  here: 'https://geocode.search.hereapi.com/v1/geocode?in=countryCode:USA',
  home: {
    index: '/home',
    userData: '/home/user_data',
  },
  deals: {
    index: '/deals',
    identifier: '/deals/{id}',
  },
  membership: {
    index: '/membership',
    slug: '/membership/{slug}',
    LocationVehicleModelsAndCalendars:
      '/membership/location_vehicle_models_and_calendars',
  },
  motorcycleTrips: {
    index: '/trips',
    id: '/trips/{id}',
  },
  vehicleModel: {
    index: '/location_vehicle_model',
    review: '/vehicle_models/{id}/review',
    reviewVote: '/reviews/{id}/vote',
    reviews: '/vehicle_models/{identifier}/reviews',
  },
  rentals: '/rentals',
  pickupPlaces: '/v2/pickup_places',
  nearbyDropoffLocations: '/nearby_dropoff_locations',
  rides: {
    index: '/rides',
    id: '/rides/{id}',
    review: '/rides/{id}/review',
    vote: '/reviews/{id}/vote',
    reviews: '/rides/{identifier}/reviews',
  },
  pageRedirects: '/page_redirects',
  faqs: '/faqs',
  rental: {
    index: '/locations/{location_id}/rentals',
  },
  articles: {
    index: '/articles',
  },
  reviews: '/reviews',
  blogDetails: '/blog_posts/{identifier}',
  blogsList: '/blog_posts',
  locaitonReviews: '/locations/{identifier}/reviews',
  locationReview: '/locations/{id}/review',
  locationGeoInfo: '/locations/{identifier}/geo_info',
  reviewVote: '/reviews/{id}/vote',
  affiliateProgram: '/affiliate_program',
  hotelPartners: '/hotel_partner',
  personalInformation: '/user/personal_information',
  vehicleReservationFeedbacks: '/vehicle_reservation_feedbacks',
  vehicleReservationFeedbacksByLocation:
    '/locations/{identifier}/vehicle_reservation_feedbacks',
  chats: '/chats',
  vehicleReservationFeedbacksByToken: '/vehicle_reservation_feedbacks/{id}',
  testEmbeddings: '/embeddings/match',
};
