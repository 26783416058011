import React from 'react';
import { styled } from '@mui/material/styles';
import useCookies from 'views/common/hooks/useCookies';
import { useTranslation } from 'next-i18next';
import Typography from '@mui/material/Typography';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Hidden } from '@mui/material';
import PATHS from 'lib/navigation/paths';
import { useSelector } from 'react-redux';

const PREFIX = 'Cookies';

const classes = {
  root: `${PREFIX}-root`,
  close: `${PREFIX}-close`,
  info: `${PREFIX}-info`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  submit: `${PREFIX}-submit`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    position: 'fixed',
    bottom: 0,
    padding: theme.spacing(2, 0, 2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 0, 2),
    },
    width: '100%',
    backgroundColor: 'rgba(17,17,17,0.96)',
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    zIndex: 3,
  },

  [`& .${classes.close}`]: {
    textAlign: 'right',
    "& [class*='MuiButtonBase-root']": {
      color: 'rgba(255, 255, 255, 0.75)',
    },
  },

  [`& .${classes.info}`]: {
    height: '100%',
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('md')]: {
      borderLeft: 'none',
      padding: theme.spacing(0),
    },
    [`& .${classes.title}`]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    [`& .${classes.description}`]: {
      color: 'rgba(255, 255, 255, 0.75)',
      display: 'inline-block',
      lineHeight: 1.3,
      margin: theme.spacing(1, 0),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0),
      },
      '& a': {
        color: theme.palette.primary.main,
        textTransform: 'uppercase !important',
        fontWeight: 500,
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  },

  [`& .${classes.title}`]: {},
  [`& .${classes.description}`]: {},

  [`& .${classes.submit}`]: {
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    "& [class*='MuiButtonBase-root']": {
      borderColor: theme.palette.primary.main,
      fontSize: '0.875rem',
      lineHeight: 1.3,
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0),
      },
      transition: theme.transitions.create(['color', 'background-color'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.short,
      }),
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
      },
    },
  },
}));

const Cookies = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const [cookies, setCookie] = useCookies();
  const isCrawler = useSelector((state) => state.globalData.isCrawler);

  const handleClose = () => {
    setExpanded(false);
    const expiryTime = new Date();
    expiryTime.setFullYear(expiryTime.getFullYear() + 1);
    setCookie('ac', '1', {
      expires: expiryTime,
    });
  };
  React.useEffect(() => {
    if (isCrawler) return;
    if (cookies.ac != '1') {
      setExpanded(true);
      setTimeout(() => {
        handleClose();
      }, 30000);
    }
  }, []);

  const closeButton = (
    <div className={classes.close}>
      <IconButton onClick={handleClose} aria-label="Close" size="large">
        <CloseIcon />
      </IconButton>
    </div>
  );

  return (
    <Root>
      <Box hidden={!expanded} direction="up">
        <div className={classes.root}>
          <Container>
            <Grid container spacing={2}>
              <Grid item md={9} xs={12}>
                <div className={classes.info}>
                  <div className={classes.title}>
                    <Typography variant="subtitle2">
                      {t('fe_er_common_layout:site_use_cookies')}
                    </Typography>
                    <Hidden mdUp implementation="css">
                      {closeButton}
                    </Hidden>
                  </div>
                  <Typography variant="caption" className={classes.description}>
                    {t('fe_er_common_layout:cookies_privacy_policy')}
                    .&nbsp;
                    <NavigatorLink
                      href={PATHS.privacyPolicy()}
                      color="primary"
                      target="_blank">
                      {t('fe_er_common_layout:er_privacy_policy')}
                    </NavigatorLink>
                  </Typography>
                </div>
              </Grid>
              <Grid item md={3} xs={12}>
                <Hidden mdDown implementation="css">
                  {closeButton}
                </Hidden>
                <div className={classes.submit}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                    size="large">
                    {t('fe_er_common_layout:close_dont_show')}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Box>
    </Root>
  );
};

export default Cookies;
