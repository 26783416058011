/* eslint-disable global-require */
/* eslint-disable no-nested-ternary */

const { envVars } = (require('next/config')
  && require('next/config').default()
  && require('next/config').default().publicRuntimeConfig) || {
  envVars: process.env,
};

const { ENVIRONMENT } = envVars;

const namedLocales = [
  'motorrad-mieten',
  'location-moto',
  'alquiler-de-motos',
  'noleggio-moto',
  'aluguel-de-motos',
  'motorverhuur',
  'motorsykkelleie',
  'motorcykeludlejninger',
  'ru',
  'ar',
  'ko',
  'sv',
  'ja',
  'en',
  'en-AU',
  'en-CA',
  'en-GB',
  'zh',
];

const locales = [
  'ar',
  'da',
  'de',
  'en',
  'en-AU',
  'en-CA',
  'en-GB',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'nl',
  'no',
  'pt',
  'ru',
  'sv',
  'zh',
];

const mapNamedLocaleToLocale = {
  en: 'en',
  'motorrad-mieten': 'de',
  'location-moto': 'fr',
  'alquiler-de-motos': 'es',
  'noleggio-moto': 'it',
  'aluguel-de-motos': 'pt',
  motorverhuur: 'nl',
  motorsykkelleie: 'no',
  motorcykeludlejninger: 'da',
  ru: 'ru',
  ar: 'ar',
  ko: 'ko',
  sv: 'sv',
  ja: 'ja',
  'en-AU': 'en-AU',
  'en-CA': 'en-CA',
  'en-GB': 'en-GB',
  zh: 'zh',
};
const mapLocaleToNamedLocale = {
  en: 'en',
  de: 'motorrad-mieten',
  fr: 'location-moto',
  es: 'alquiler-de-motos',
  nl: 'motorverhuur',
  it: 'noleggio-moto',
  pt: 'aluguel-de-motos',
  ru: 'ru',
  ar: 'ar',
  ko: 'ko',
  sv: 'sv',
  no: 'motorsykkelleie',
  da: 'motorcykeludlejninger',
  ja: 'ja',
  'en-AU': 'en-AU',
  'en-CA': 'en-CA',
  'en-GB': 'en-GB',
  zh: 'zh',
};

const mapSubdomainToLocale = {
  [ENVIRONMENT == 'development'
    ? 'eaglerider.lcom.au'
    : ENVIRONMENT == 'prelive'
      ? 'prelive-www.eaglerider.com.au'
      : 'www.eaglerider.com.au']: 'en-AU',
  [ENVIRONMENT == 'development'
    ? 'eaglerider.lcn'
    : ENVIRONMENT == 'prelive'
      ? 'prelive-www.eaglerider.cn'
      : 'www.eaglerider.cn']: 'zh',
  [ENVIRONMENT == 'development'
    ? 'eaglerider.lco.uk'
    : ENVIRONMENT == 'prelive'
      ? 'prelive-www.eaglerider.co.uk'
      : 'www.eaglerider.co.uk']: 'en-GB',
  [ENVIRONMENT == 'development'
    ? 'eaglerider.lca'
    : ENVIRONMENT == 'prelive'
      ? 'prelive-www.eaglerider.ca'
      : 'www.eaglerider.ca']: 'en-CA',
  [ENVIRONMENT == 'development'
    ? 'eaglerider.lcom'
    : ENVIRONMENT == 'prelive'
      ? 'prelive-www.eaglerider.com'
      : 'www.eaglerider.com']: 'en',
};
const mapLocaleToSubdomain = {
  'en-AU':
    ENVIRONMENT == 'development'
      ? 'eaglerider.lcom.au'
      : ENVIRONMENT == 'prelive'
        ? 'prelive-www.eaglerider.com.au'
        : 'www.eaglerider.com.au',
  zh:
    ENVIRONMENT == 'development'
      ? 'eaglerider.lcn'
      : ENVIRONMENT == 'prelive'
        ? 'prelive-www.eaglerider.cn'
        : 'www.eaglerider.cn',
  'en-GB':
    ENVIRONMENT == 'development'
      ? 'eaglerider.lco.uk'
      : ENVIRONMENT == 'prelive'
        ? 'prelive-www.eaglerider.co.uk'
        : 'www.eaglerider.co.uk',
  'en-CA':
    ENVIRONMENT == 'development'
      ? 'eaglerider.lca'
      : ENVIRONMENT == 'prelive'
        ? 'prelive-www.eaglerider.ca'
        : 'www.eaglerider.ca',
};
const mainDomain = {
  en:
    ENVIRONMENT === 'development'
      ? 'eaglerider.lcom'
      : ENVIRONMENT === 'prelive'
        ? 'prelive-www.eaglerider.com'
        : 'www.eaglerider.com',
};

const mapNamedLocaleToCountryCode = {
  ar: 'SA',
  motorcykeludlejninger: 'DK',
  'motorrad-mieten': 'DE',
  en: 'US',
  'en-AU': 'AU',
  'en-CA': 'CA',
  'en-GB': 'GB',
  'alquiler-de-motos': 'ES',
  'location-moto': 'FR',
  'noleggio-moto': 'IT',
  ja: 'JP',
  ko: 'KR',
  motorverhuur: 'NL',
  motorsykkelleie: 'NO',
  'aluguel-de-motos': 'BR',
  ru: 'RU',
  sv: 'SE',
  zh: 'CN',
};

const mapERLocaleToESLocale = {
  'motorrad-mieten': 'de',
  'location-moto': 'fr',
  'alquiler-de-motos': 'es',
  'en-GB': 'uk',
  'en-CA': 'ca',
  'en-AU': 'au',
  motorverhuur: 'nl',
  'noleggio-moto': 'it',
  'aluguel-de-motos': 'br',
  ru: 'ru',
  ar: 'ar',
  ko: 'kr',
  sv: 'se',
  motorsykkelleie: 'no',
  motorcykeludlejninger: 'dk',
  ja: 'jp',
  zh: 'cn',
};

const subdomainLocalesArr = ['en', 'en-CA', 'en-AU', 'en-GB', 'zh'];

module.exports = {
  mapLocaleToSubdomain,
  mapSubdomainToLocale,
  mapLocaleToNamedLocale,
  mapNamedLocaleToLocale,
  mapNamedLocaleToCountryCode,
  mainDomain,
  namedLocales,
  locales,
  mapERLocaleToESLocale,
  subdomainLocalesArr,
};
