import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { useTranslation } from 'next-i18next';
import PATHS from 'lib/navigation/paths';
import Image from 'next/image';
import { Box } from '@mui/material';
import blur from 'public/imgs/1x1.webp';

const PREFIX = 'Copyright';

const classes = {
  root: `${PREFIX}-root`,
  logo: `${PREFIX}-logo`,
  typography: `${PREFIX}-typography`,
  esLogoBox: `${PREFIX}-esLogoBox`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    padding: '0 2rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0 4rem',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  [`& .${classes.logo}`]: {
    width: theme.typography.pxToRem(96),
    marginTop: '-0.625rem',
    marginBottom: '0.625rem',
  },

  [`& .${classes.typography}`]: {
    margin: theme.spacing(0, 6.25),
    fontWeight: 600,
    fontSize: '0.9375rem',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '1rem',
    },
  },
}));

const Copyright = () => {
  const { t } = useTranslation();
  return (
    <Root className={`${classes.root}`}>
      <NavigatorLink href={PATHS.home()}>
        <Box className={classes.logo}>
          <Image
            src="/imgs/logo.png"
            width={96}
            height={75}
            alt={t('fe_er_common_layout:EagleRider')}
            placeholder="blur"
            blurDataURL={blur.src}
          />
        </Box>
      </NavigatorLink>
      <Typography variant="subtitle2" className={classes.typography}>
        {t('fe_er_common_layout:copyright')}
        {' '}
        &copy;
        {new Date().getFullYear()}
        {' '}
        {t('fe_er_common_layout:EagleRider')}
        .
      </Typography>
    </Root>
  );
};

export default Copyright;
