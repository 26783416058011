import { useRef, useState, useEffect } from 'react';

const useShouldHydratApp = () => {
  const ref = useRef(null);
  const [render, setRender] = useState(typeof window === 'undefined');

  useEffect(() => {
    const isEmpty = ref.current?.innerHTML === '';
    if (isEmpty) {
      setRender(true);
    }
  }, []);

  return [render, ref];
};

export default useShouldHydratApp;
