/* eslint-disable react/no-array-index-key */
/* eslint-disable no-script-url */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';
// Material ui components
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useHeaderMenuItems from 'views/common/hooks/useHeaderMenuItems';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import CountrySelector from 'views/common/components/Logical/Layout/TopBar/CountrySelector';
import CurrencySelector from 'views/common/components/Logical/Layout/TopBar/CurrencySelector';
import DarkButton from 'views/common/components/Logical/Layout/DarkButton';
import PATHS from 'lib/navigation/paths';
import mapPathnameToBreadcrumbId from 'views/common/components/Logical/Layout/utils/mapPathnameToBreadcrumbId';
import useAppNavigator from 'views/common/hooks/useAppNavigator';

const MenuDialogs = dynamic(() => import('views/common/components/Logical/Layout/MenuBar/MenuDialogs'));

const PREFIX = 'MobileNavigationDrawer';

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  homeLink: `${PREFIX}-homeLink`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  list: `${PREFIX}-list`,
  listItemLink: `${PREFIX}-listItemLink`,
  expandIcon: `${PREFIX}-expandIcon`,
  submenuItem: `${PREFIX}-submenuItem`,
  submenuIcon: `${PREFIX}-submenuIcon`,
  drawerFooter: `${PREFIX}-drawerFooter`,
  footerActions: `${PREFIX}-footerActions`,
  rentBikeLink: `${PREFIX}-rentBikeLink`,
  newLabel: `${PREFIX}-newLabel`,
};
const drawerWidth = '20rem';

const Root = styled('div')(({ theme }) => ({
  height: '100%',

  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    backgroundColor: [theme.palette.secondary.dark],
  },

  [`& .${classes.toolbar}`]: {
    ...theme.mixins.toolbar,
    padding: theme.spacing(0, 1.5),
    background: theme.palette.grey[700],
    display: 'flex',
    alignItems: 'center',
    [`& .${classes.homeLink}`]: {
      fontSize: '1.1875rem',
      fontFamily: theme.typography.fontFamilySecondary,
      textTransform: 'uppercase',
      fontWeight: 700,
      color: theme.palette.common.white,
    },
  },

  [`& .${classes.list}`]: {
    padding: theme.spacing(0),
    "& [class*='MuiListItem-root']": {
      padding: `${theme.spacing(0, 0.5)} !important`,
    },
  },

  [`& .${classes.listItemLink}`]: {
    padding: theme.spacing(1.1, 1),
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    textTransform: 'uppercase',
    position: 'relative',
    "& [class*='MuiSvgIcon-root']": {
      fontSize: '1rem',
      marginRight: theme.typography.pxToRem(4),
    },
  },

  [`& .${classes.expandIcon}`]: {
    fontSize: '1.2rem',
    margin: '0.5rem 0.7rem',
    color: theme.palette.text.secondary,
  },

  [`& .${classes.submenuItem}`]: {
    "& [class*='MuiListItemText-root']": {
      marginTop: theme.typography.pxToRem(2),
      marginBottom: theme.typography.pxToRem(2),
    },
    [`& .${classes.listItemLink}`]: {
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'capitalize',
    },
    [`& .${classes.submenuIcon}`]: {
      position: 'absolute',
      right: theme.typography.pxToRem(5),
    },
  },

  [`& .${classes.drawerFooter}`]: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [`& .${classes.footerActions}`]: {
      '& > *': {
        margin: theme.spacing(0, 1),
        width: '50%',
      },
    },
  },

  [`& .${classes.rentBikeLink}`]: {
    margin: theme.spacing(3, 2),
    borderColor: '#fff',
  },
  '& .MNDFilledMembership': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    padding: theme.spacing(1),
    borderRadius: 4,
  },
  '& .MNDSupText': {
    position: 'relative',
    top: theme.spacing(-0.5),
    fontSize: theme.typography.pxToRem(10),
    fontStyle: 'italic',
    textDecoration: 'underline',
    paddingLeft: theme.spacing(0.5),
    fontWeight: '800',
  },
}));

const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    height: '100%',
  },
}));
const MobileNavigationDrawer = (props) => {
  const { mobileOpen, handleDrawerClose, handleDrawerOpen } = props;
  const { t } = useTranslation();
  const menuItems = useHeaderMenuItems(true);
  const appNavigator = useAppNavigator();
  const [items, setItems] = useState(menuItems);
  // Check clicked menu items to open dialogs if necessarily
  const [clickedItem, setClickedItem] = useState(null);

  useEffect(() => {
    setItems(menuItems);
  }, [menuItems]);

  const handleItemClick = (item, index) => (event) => {
    if (item.submenu) {
      // expand or collapse submenu
      event.preventDefault();
      let oldItems = [...items];
      oldItems = oldItems.map((_item, i) => ({
        ..._item,
        isOpen: i === index ? !_item.isOpen : false, // Close all expanded items and toggle the clicked one
      }));
      setItems(oldItems);
      handleDrawerOpen(); // To keep side menu open in case of submenu
    } else if (item && item.href && item.href.url.includes('javascript:')) {
      event.preventDefault();
      setClickedItem(item);
    } else if (
      typeof window !== 'undefined'
      && mapPathnameToBreadcrumbId[appNavigator.pathname]
      && item?.href.url?.startsWith(appNavigator.pathname)
    ) {
      if (
        document.getElementById(
          mapPathnameToBreadcrumbId[appNavigator.pathname],
        )
      ) {
        document
          .getElementById(mapPathnameToBreadcrumbId[appNavigator.pathname])
          .click();
        event.preventDefault();
      }
    }
  };
  const drawer = (
    <Root>
      <div className={classes.root}>
        <div className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleDrawerClose}
            aria-label="menu"
            size="large"
            sx={{
              padding: '14px !important',
              borderRadius: '50% !important',
              color: 'white !important',
            }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            display="inline"
            className={classes.homeLink}
            component={NavigatorLink}
            href={PATHS.home()}
            sx={{
              padding: '14px !important',
              borderRadius: '50% !important',
              color: 'white !important',
            }}>
            {t('fe_er_common_layout:EagleRider')}
          </Typography>
        </div>
        <List className={classes.list}>
          {items.map((item, index) => (
            <React.Fragment key={item.id}>
              <ListItem
                onClickCapture={handleItemClick(item, index)} // Capture phase
                button
                selected={item.isOpen}>
                <ListItemText
                  primary={(
                    <NavigatorLink
                      className={classes.listItemLink}
                      translate={item.translate}
                      onClick={(!item.submenu && handleDrawerClose) || null}
                      href={item.href}
                      color="textSecondary">
                      {item.id != 'membership' ? (
                        <>
                          {t(item.text)}
                          {item.id === 'off-road' && (
                            <Box component="span" className="MNDSupText">
                              {t(item.supText)}
                            </Box>
                          )}
                        </>
                      ) : (
                        <span className="MNDFilledMembership">
                          {t(item.text)}
                        </span>
                      )}
                    </NavigatorLink>
                  )}
                />
                {item.submenu ? (
                  item.isOpen ? (
                    <KeyboardArrowUpIcon className={classes.expandIcon} />
                  ) : (
                    <KeyboardArrowDownIcon className={classes.expandIcon} />
                  )
                ) : null}
              </ListItem>
              {item.submenu && item.submenu.items && (
                <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
                  <List disablePadding>
                    {item.submenu.items.map(
                      (submenuCategory, submenuCategoryIndex) => (
                        <React.Fragment key={submenuCategoryIndex}>
                          {submenuCategory
                            && submenuCategory.map((submenuItem) => (
                              <ListItem
                                key={submenuItem.text}
                                onClickCapture={handleItemClick(submenuItem)} // Capture phase
                                button
                                className={classes.submenuItem}>
                                <ListItemText
                                  primary={(
                                    <NavigatorLink
                                      translate={submenuItem.translate}
                                      className={classes.listItemLink}
                                      href={submenuItem.href}
                                      onClick={() => {
                                        handleDrawerClose(submenuItem);
                                      }}
                                      color="textSecondary">
                                      <KeyboardArrowRightIcon />
                                      {t(submenuItem.text)}
                                      {submenuItem.is_harley_davidson && (
                                        <Avatar
                                          alt={t(submenuItem.text)}
                                          src="/imgs/harley-davidson.svg"
                                          variant="rounded"
                                          className={classes.submenuIcon}
                                        />
                                      )}
                                    </NavigatorLink>
                                  )}
                                />
                              </ListItem>
                            ))}
                        </React.Fragment>
                      ),
                    )}
                  </List>
                </Collapse>
              )}
              {items.length !== index + 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
        <Box>
          <DarkButton
            variant="outlined"
            component={NavigatorLink}
            href={PATHS.rentMyMotorcycle()}
            className={classes.rentBikeLink}
            onClick={handleDrawerClose}>
            {t('fe_er_common_layout:rent_my_bike')}
          </DarkButton>
        </Box>
        <div className={classes.drawerFooter}>
          <Box
            className={classes.footerActions}
            display="flex"
            justifyContent="space-around"
            m={2}>
            <CurrencySelector />
            <CountrySelector />
          </Box>
        </div>
      </div>
    </Root>
  );
  return (
    <>
      <StyledDrawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}>
        {drawer}
      </StyledDrawer>
      {/* Custom trip && CheckInDialogs */}
      {clickedItem && (
        <MenuDialogs item={clickedItem} onClose={() => setClickedItem(null)} />
      )}
    </>
  );
};

export default MobileNavigationDrawer;
