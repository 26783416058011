const useCookies = () => {
  if (typeof document === 'undefined') return [{}, undefined];
  const reEvaluateCookies = () => {
    const cookiesArray = document?.cookie.split('; ');
    const cookiesObj = {};
    cookiesArray.forEach((cookie) => {
      const [key, value] = cookie.split('=');
      const cookieValue = decodeURIComponent(value);
      try {
        cookiesObj[key] = JSON.parse(cookieValue);
      } catch {
        cookiesObj[key] = cookieValue;
      }
    });
    return cookiesObj;
  };
  const setCookie = (key, value, opt) => {
    let cookieString = `${key}=${encodeURIComponent(
      typeof value === 'object' ? JSON.stringify(value) : value,
    )};`;
    if (opt?.expires) cookieString += ` expires=${opt.expires};`;
    if (opt?.path) cookieString += `  path=${opt.path};`;
    if (opt?.sameSite) cookieString += `  SameSite=${opt.sameSite};`;
    if (opt?.secure) cookieString += '  Secure';
    document.cookie = cookieString;
    reEvaluateCookies();
  };

  const removeCookie = (key) => {
    document.cookie = `${key}=; Max-Age=-99999999;`;
  };
  const cookies = reEvaluateCookies();
  return [cookies, setCookie, removeCookie];
};

export default useCookies;
