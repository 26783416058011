import React from 'react';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import reviews from 'public/imgs/aggregatedRatings/reviews.svg';
import starRating from 'public/imgs/aggregatedRatings/starRating.svg';
import StyledNavigatorLink, {
  StyledImgBox,
} from 'views/common/components/Logical/Layout/Footer/Sitemap/AggregatedRatings/AggregatedRatings.styles';
import Typography500 from 'views/common/components/UI/Typography500';
import PATHS from 'lib/navigation/paths';

const AggregatedRatings = () => {
  const { t } = useTranslation();
  const appState = useSelector((state) => state.globalData.appState);

  return (
    <StyledNavigatorLink href={PATHS.reviews()}>
      <Box className="ARContainer">
        <Box className="ARItemContainer">
          <StyledImgBox
            image={{
              src: starRating,
              alt: t('fe_er_common_layout:average_rating'),
            }}
            imgWidth="30px"
            imgHeight="28px"
          />
          <Box>
            <Typography500 variant="h4" component="p">
              {(+appState.vehicle_reservation_feedbacks_metrics
                .aggregate_rating).toFixed(1)}
            </Typography500>
            <Typography500 className="ARSubtitle">
              {t('fe_er_common_layout:average_rating')}
            </Typography500>
          </Box>
        </Box>
        <Box className="ARItemContainer">
          <StyledImgBox
            image={{
              src: reviews,
              alt: t('fe_er_common_layout:ratings'),
            }}
            imgWidth="30px"
            imgHeight="30px"
          />
          <Box>
            <Typography500 variant="h4" component="p">
              {appState.vehicle_reservation_feedbacks_metrics.retail_surveys_submitted.toLocaleString(
                'en-US',
              )}
            </Typography500>
            <Typography500 className="ARSubtitle">
              {t('fe_er_common_layout:ratings')}
            </Typography500>
          </Box>
        </Box>
      </Box>
    </StyledNavigatorLink>
  );
};

export { AggregatedRatings };
