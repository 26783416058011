import React from 'react';
import { Box, Divider, Container } from '@mui/material';
import StyledBox, {
  StyledImgBox,
} from 'views/common/components/Logical/Layout/Footer/LinksFooter/LinksFooter.styles';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import PATHS from 'lib/navigation/paths';

const LinksFooter = () => {
  const { t } = useTranslation('fe_er_common_layout');
  const appleStore = {
    src: '/imgs/appAD/AppStore.png',
    alt: 'Download on the App Store',
  };
  const googlePlay = {
    src: '/imgs/appAD/GooglePlay.png',
    alt: 'Get it on Google Play',
  };
  return (
    <StyledBox textAlign="center" pb={4}>
      <Container maxWidth="xl">
        <Divider />
        <Typography500 component="h4" variant="h4" py={2}>
          {t('fe_er_common_layout:download_app')}
        </Typography500>

        <Box className="LFdownloadBtns">
          <NavigatorLink href={PATHS.app.ios}>
            <StyledImgBox
              className="LFDownload"
              imgHeight="3.25rem"
              imgWidth="11.25rem"
              image={appleStore}
              objectFit="contain"
            />
          </NavigatorLink>

          <NavigatorLink pl={1.25} href={PATHS.app.playStore}>
            <StyledImgBox
              className="LFDownload"
              imgHeight="3.25rem"
              imgWidth="11.25rem"
              image={googlePlay}
              objectFit="contain"
            />
          </NavigatorLink>
        </Box>
      </Container>
    </StyledBox>
  );
};

export { LinksFooter };
