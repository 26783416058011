const loadScript = (scr, loadFn) => {
  const script = document.createElement('script');
  script.onload = () => {
    loadFn();
  };
  script.src = scr;

  document.head.appendChild(script);
};

export default loadScript;
