import { mapNamedLocaleToLocale } from 'lib/data/localesMap';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const { API_PROTOCOL, EN_DOMAIN } = envVars;

const generateRentalNavigationUrl = (url, locale) => {
  const subPath = locale !== 'en' ? `/${mapNamedLocaleToLocale[locale]}` : '';
  const baseURL = `${API_PROTOCOL}://${EN_DOMAIN}/agent`;
  return `${baseURL}${subPath}${url}`;
};

export default generateRentalNavigationUrl;
