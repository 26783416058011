import React, { useMemo } from 'react';
import dynamic from 'next/dynamic';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import LoadingSpinner from 'views/common/components/Logical/Layout/LoadingSpinner';
import SnackBar from 'views/common/components/Logical/Layout/SnackBar';
import TopBar from 'views/common/components/Logical/Layout/TopBar';
import layoutDarkTheme from 'views/common/components/Logical/Layout/layoutDarkTheme';
import MenuBar from 'views/common/components/Logical/Layout/MenuBar';
import { useSelector } from 'react-redux';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import Head from 'next/head';
import Footer from 'views/common/components/Logical/Layout/Footer';
import { AppAdMobile } from 'views/common/components/UI/AppAdMobile';

const RPFooter = dynamic(() => import('views/common/components/Logical/Layout/RPFooter').then(
  (file) => file.RPFooter,
));

const RPTopLayout = dynamic(() => import('views/common/components/Logical/Layout/RPTopLayout').then(
  (file) => file.RPTopLayout,
));
const ChatBotFBDialog = dynamic(() => import('views/common/components').then((file) => file.ChatBotFBDialog));
const PendingReservations = dynamic(() => import('views/common/components/Logical/Layout/PendingReservations'));
const ErrorPage = dynamic(() => import('views/common/components/Logical/Layout/ErrorPage'));
const CaptureEmailOverlay = dynamic(() => import('views/common/components').then((file) => file.CaptureEmailOverlay));

const { envVars } = require('next/config').default().publicRuntimeConfig;

const { GTM_CODE } = envVars;

const Layout = (props) => {
  const { children, options = {} } = props;
  const {
    showCaptureEmailOverlay = true,
    showFooter = true,
    showNeedHelpComponent = true,
    showCookieNotification = true,
    showPendingReservationMessage = true,
    showAiChat = false,
    renderRPLayout = false,
    skipLayoutFromGlobal = false,
    showLayout = true,
  } = options;
  const appNavigator = useAppNavigator();
  const {
    appState, isCrawler, isSSR, isInIframe,
  } = useSelector(
    (state) => state.globalData,
  );
  const error = useSelector((state) => state.ui.error);
  const shouldRenderAiBot = useSelector((state) => state.ui.shouldRenderAiBot);

  const hideLayout = useMemo(
    () => typeof ReactNativeWebView !== 'undefined'
      || appNavigator.query.hideLayout === '1'
      || isInIframe
      || !showLayout,
    [],
  );
  const renderTopLayout = () => {
    const topLayout = renderRPLayout ? (
      <RPTopLayout />
    ) : (
      <>
        <TopBar />
        <MenuBar />
      </>
    );

    if (isCrawler) {
      return topLayout;
    }

    if (!isSSR && typeof window === 'undefined') return null;
    if (typeof ReactNativeWebView === 'undefined') return topLayout;

    return null;
  };
  const renderFooter = () => {
    if (renderRPLayout) {
      return <RPFooter />;
    }
    return (
      <Footer
        showNeedHelpComponent={showNeedHelpComponent}
        showCookieNotification={showCookieNotification}
      />
    );
  };

  return (
    <Box className="LayoutContainer">
      <Head>
        {Object.keys(appState).length > 0
          && !appState.current_user?.privileged
          && !isCrawler && (
            <>
              <script
                defer
                dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_CODE}');`,
                }}
              />
              <script
                defer
                src={`https://www.googletagmanager.com/gtag/js?id=${GTM_CODE}`}
              />
              <script
                dangerouslySetInnerHTML={{
                  __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GTM_CODE}');`,
                }}
              />
            </>
        )}
      </Head>
      <ThemeProvider theme={layoutDarkTheme}>
        <Box className="showTABLET">
          {!isCrawler
            && typeof ReactNativeWebView === 'undefined'
            && !isInIframe && (
              <AppAdMobile
                localStorageName="hide_mobile_app_ad"
                localStorageValue={1}
              />
          )}
        </Box>

        <LoadingSpinner />
        <SnackBar />
        {!hideLayout && renderTopLayout()}
        {appState
          && appState.current_reservation
          && appState.current_reservation.state
          && showPendingReservationMessage && <PendingReservations />}
      </ThemeProvider>
      <main>
        {error.statusCode && (
          <ErrorPage
            statusCode={error.statusCode}
            title={error.title}
            subtitle={error.subtitle}
          />
        )}
        {skipLayoutFromGlobal ? (
          <Box hidden={error.statusCode}>{children}</Box>
        ) : (
          <Box hidden={error.statusCode}>
            {Object.keys(appState).length > 0 && children}
          </Box>
        )}
      </main>
      {showFooter && Object.keys(appState).length > 0 && !hideLayout && (
        <ThemeProvider theme={layoutDarkTheme}>
          <Box className="FooterContainer">{renderFooter()}</Box>
        </ThemeProvider>
      )}
      {!isCrawler
        && (showAiChat || shouldRenderAiBot)
        && appState?.settings?.enable_rider_ai && <ChatBotFBDialog />}

      {!isCrawler
        && showCaptureEmailOverlay
        && !appState.current_user
        && appState?.settings?.show_capture_email_overlay.enabled
        && typeof ReactNativeWebView === 'undefined'
        && !isInIframe && <CaptureEmailOverlay />}
      {appState.structured_data && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(appState.structured_data),
          }}
        />
      )}
    </Box>
  );
};

export default Layout;
