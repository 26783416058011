import { configureStore } from '@reduxjs/toolkit';
import uiReducer from 'redux/features/ui-slice';
import globalDataReducer from 'redux/features/globalData-slice';

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    globalData: globalDataReducer,
  },
});
