/* eslint-disable consistent-return */
import * as Airbrake from '@airbrake/node';
import axios from 'axios';
import isServerSide from 'lib/utils/isServerSide';
import loadScript from 'lib/utils/loadScript';

let airbrake;
const erCodeDirs = ['pages', 'lib/utils', 'views', 'redux'];

export const notifyAirbrake = (error) => {
  if (airbrake) {
    return airbrake.notify(error);
  }
};

let appErrors;

const reportAppError = async (error) => {
  try {
    const { backtrace } = error;

    const { data: siteMapFile } = await axios.get(
      backtrace[0].file.replace('.js', '.js.map'),
    );
    const consumer = await new sourceMap.SourceMapConsumer(siteMapFile);

    const orignalFile = consumer.originalPositionFor({
      line: backtrace[0].line,
      column: backtrace[0].column,
    });

    const erDirRegex = new RegExp(`\\/${erCodeDirs.join('\\/|\\/')}\\/`);
    if (erDirRegex.test(orignalFile.source)) {
      appErrors = error;
      notifyAirbrake(error);
    }
  } catch (error) {
    notifyAirbrake(error);
  }
};

const isErrorComingFromNotify = (backtrace) => {
  return backtrace[0].function.includes('.notify');
};

const isAirbrakeCodeError = (message) => {
  if (
    message.includes('Timeout') ||
    message.includes('Object Not Found Matching ') ||
    message.includes("Cannot set property 'vt' of undefined") ||
    message.includes("Cannot read property 'setPageIdValue' of undefined")
  ) {
    // Ignore errors with this messsage
    return true;
  }
};

export const initAirbrake = (initialEnvVars) => {
  let envVars = initialEnvVars;
  if (!envVars) {
    envVars = require('next/config').default().publicRuntimeConfig.envVars;
  }
  if (envVars.ENVIRONMENT == 'prelive' || envVars.ENVIRONMENT == 'production') {
    if (envVars.AIRBRAKE_PROJECT_ID && envVars.AIRBRAKE_PROJECT_KEY) {
      airbrake = new Airbrake.Notifier({
        projectId: envVars.AIRBRAKE_PROJECT_ID,
        projectKey: envVars.AIRBRAKE_PROJECT_KEY,
        environment: `${envVars.ENVIRONMENT} ${
          typeof window === 'undefined' ? '[SSR]' : '[CSR]'
        }`,
      });

      airbrake.addFilter((notice) => {
        const { backtrace, message } = notice.errors[0];

        if (isServerSide()) return notice;

        if (isAirbrakeCodeError(message)) return null;

        if (isErrorComingFromNotify(backtrace)) {
          if (appErrors) {
            notice.errors = [appErrors];
          }
          appErrors = null;
          return notice;
        } else {
          if ('sourceMap' in document) reportAppError(notice.errors[0]);
          else
            loadScript(
              'https://unpkg.com/source-map@0.7.3/dist/source-map.js',
              () => {
                sourceMap.SourceMapConsumer.initialize({
                  'lib/mappings.wasm':
                    'https://unpkg.com/source-map@0.7.3/lib/mappings.wasm',
                });
                reportAppError(notice.errors[0]);
              },
            );
          return null;
        }
      });
    }
  }
};
