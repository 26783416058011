const filterQuerySegments = (query) => {
  const filteredQuery = {};
  Object.entries(query).forEach(([key, value]) => {
    if (key.endsWith('_segment')) return;
    filteredQuery[key] = value;
  });
  return filteredQuery;
};

export default filterQuerySegments;
