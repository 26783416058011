import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import Image from 'next/image';

const StyledBox = styled(Box)(
  ({
    theme, imgWidth, imgHeight, imgObjectFit,
  }) => ({
    '&.IBimgbox': {
      width: imgWidth || '100%',
      height: imgHeight || '100%',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    '& .IBimg': {
      objectFit: imgObjectFit || 'cover',
    },
  }),
);

const ImgBox = (props) => {
  const {
    image,
    imgWidth,
    imgHeight,
    className,
    imgObjectFit,
    ...restOfProps
  } = props;
  return (
    <StyledBox
      className={`IBimgbox ${className}`}
      imgWidth={imgWidth}
      imgHeight={imgHeight}
      imgObjectFit={imgObjectFit}>
      <Image
        className="IBimg"
        data-src={image.src}
        src={image.src}
        alt={image.alt}
        // placeholder="blur"
        // blurDataURL={blur.src}
        layout="fill"
        unoptimized
        {...restOfProps}
      />
    </StyledBox>
  );
};
export { ImgBox };
