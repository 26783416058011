import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PATHS from 'lib/navigation/paths';

export const tours = {
  id: 'tours',
  name: 'fe_er_common_layout:common.tours',
  submenu: [
    {
      text: 'fe_er_common_layout:common.motorcycle_tours',
      href: PATHS.motorcycleTours.index(),
    },
    {
      text: 'fe_er_common_layout:common.guided_motorcycle_tours',
      href: PATHS.tour.guidedTourIndex(),
    },
    {
      text: 'fe_er_common_layout:common.self_motorcycle_tours',
      href: PATHS.tour.selfTourIndex(),
    },
    {
      text: 'fe_er_common_layout:common.popular_motorcycle_trips',
      href: PATHS.motorcycleTrips.index(),
    },
  ],
};

export const information = {
  id: 'information',
  name: 'fe_er_common_layout:eaglerider_information.header',
  submenu: [
    {
      text: 'fe_er_common_layout:eaglerider_information.site_map',
      href: PATHS.siteIndex(),
    },
    {
      text: 'fe_er_common_layout:eaglerider_information.eagleRider_reviews',
      href: PATHS.reviews(),
    },
    {
      group: {
        href: PATHS.information(),
        texts: [
          {
            text: 'fe_er_common_layout:eaglerider_information.about_eaglerider',
          },
          {
            text: 'fe_er_common_layout:eaglerider_information.careers',
          },
          {
            text: 'fe_er_common_layout:eaglerider_information.insurance',
          },
          {
            text: 'fe_er_common_layout:eaglerider_information.driver_license_requirements',
          },
          {
            text: 'fe_er_common_layout:eaglerider_information.privacy_policy',
          },
          {
            text: 'fe_er_common_layout:eaglerider_information.faqs',
          },
          {
            text: 'fe_er_common_layout:eaglerider_information.terms_and_conditions',
          },
          {
            text: 'fe_er_common_layout:eaglerider_information.contact_us',
          },
          {
            text: 'fe_er_common_layout:eaglerider_information.more_info',
          },
        ],
      },
    },
  ],
};

/** *
 * Custom hook instead of static arrays because we have data that is fetched dynamically
 * from the API and to separate this logic from the components
 ** */

const useFooterSitemap = () => {
  const appState = useSelector((state) => state.globalData.appState);
  const isCrawler = useSelector((state) => state.globalData.isCrawler);
  const adjustFooterCategories = () => {
    let locations;
    let rentals;
    if (
      appState
      && appState.featured_rentals
      && appState.featured_rentals.footer
    ) {
      const rentalsSubmenu = appState.featured_rentals.footer.map((rental) => ({
        text: rental.is_harley_davidson
          ? 'fe_er_common_layout:harley_rentals'
          : `fe_er_common_layout:rentals.${rental.translation_key}_rentals`,
        href: PATHS.geoSlug(rental.slug),
        is_harley_davidson: rental.is_harley_davidson,
        translate: false,
      }));
      rentals = {
        id: 'rentals',
        name: 'fe_er_common_layout:common.rentals',
        isOpen: false,
        submenu: [...rentalsSubmenu],
        translate: false,
      };
    }
    // populate locations
    if (appState && appState.featured_locations) {
      locations = {
        id: 'locations',
        name: 'fe_er_common_layout:locations',
        isOpen: false,
        submenu: [
          ...appState.featured_locations.map((location) => ({
            text: location.name,
            href: PATHS.locationSlug(location.slug),
          })),
          {
            text: 'fe_er_common_layout:more_locations',
            href: PATHS.locations(),
          },
        ],
      };
    }
    return [{ ...rentals }, { ...tours }, { ...locations }, { ...information }];
  };
  const initCategories = () => {
    if (!isCrawler) return [];
    return adjustFooterCategories();
  };
  const [categories, setCategories] = useState(initCategories());

  useEffect(() => {
    if (isCrawler) return;
    // Return all categories static(tours,information) & dynamic (rentals,locations)
    setCategories(adjustFooterCategories());
  }, [appState]);
  return categories;
};

export default useFooterSitemap;
