import React from 'react';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { styled, alpha } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Container,
  Button,
  Typography,
  Avatar,
} from '@mui/material';
import { Person, SwapHoriz, MoreVert } from '@mui/icons-material';
import CountrySelector from 'views/common/components/Logical/Layout/TopBar/CountrySelector';
import CurrencySelector from 'views/common/components/Logical/Layout/TopBar/CurrencySelector';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import DarkButton from 'views/common/components/Logical/Layout/DarkButton';
import PATHS from 'lib/navigation/paths';

const UserDrawer = dynamic(() => import('views/common/components/Logical/Layout/TopBar/UserDrawer'));
const ForgotPasswordDialog = dynamic(() => import('views/common/components/Logical/Layout/TopBar/ForgotPasswordDialog'));
const JoinUsDrawer = dynamic(() => import('views/common/components/Logical/Layout/TopBar/JoinUsDrawer'));

const PREFIX = 'TopBar';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  signInButton: `${PREFIX}-signInButton`,
  userButton: `${PREFIX}-userButton`,
  avatar: `${PREFIX}-avatar`,
  typography: `${PREFIX}-typography`,
  userInfo: `${PREFIX}-userInfo`,
  swapIcon: `${PREFIX}-swapIcon`,
  badge: `${PREFIX}-badge`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    "& [class*='MuiAppBar-root'] [class*='MuiToolbar-root']": {
      backgroundColor: theme.palette.secondary.dark,
      zIndex: '1100',
    },
  },

  [`& .${classes.container}`]: {
    maxWidth: theme.spacing(166.25),
    textAlign: 'right',
    '& > *': {
      margin: `0 ${theme.typography.pxToRem(6)}`,
      height: theme.typography.pxToRem(34),
    },
  },

  [`& .${classes.signInButton}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textTransform: 'uppercase',
  },

  [`& .${classes.userButton}`]: {
    height: theme.typography.pxToRem(41),
    '&:not(.square)': {
      borderRadius: '1.1875rem 0.125rem 0.125rem 1.1875rem !important',
      paddingLeft: 0,
      [`& .${classes.avatar}`]: {
        width: theme.typography.pxToRem(34),
        height: theme.typography.pxToRem(34),
        boxShadow: `0 0 0 3px ${theme.palette.secondary.dark}`,
      },
      [`& .${classes.typography}`]: {
        color: alpha(theme.palette.common.white, 0.8),
        whiteSpace: 'nowrap',
        textTransform: 'none',
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(14),
      },
      [`& .${classes.userInfo}`]: {
        margin: '0.1rem 0.75rem 0 0.75rem',
        lineHeight: 1,
        textAlign: 'left',
        maxWidth: `${theme.typography.pxToRem(255)} !important`,
        '& .caption': {
          color: theme.palette.text.disabled,
          fontSize: theme.typography.pxToRem(10),
          textTransform: 'uppercase',
          letterSpacing: theme.typography.pxToRem(0.5),
          fontWeight: 400,
        },
      },
    },
  },

  [`& .${classes.swapIcon}`]: {
    fontSize: [theme.typography.pxToRem(24), '!important'],
  },

  [`& .${classes.badge}`]: {
    margin: theme.spacing(0, 0.7, 0, 1.4),
  },
  '& .companyButton': {
    textAlign: 'left',
    padding: theme.spacing(0.5, 1.2),
    lineHeight: '1',
  },
  '& .company': {
    fontSize: theme.spacing(1.25),
    color: '#ffffff80',
    letterSpacing: '.5px',
  },
  '& .companyName': {
    fontWeight: '500',
    fontSize: theme.typography.pxToRem(14),
  },
}));

const TopBar = () => {
  const appState = useSelector((state) => state.globalData.appState);
  const isCrawler = useSelector((state) => state.globalData.isCrawler);

  const isAuthenticated = appState.current_user && appState.current_user.id;
  const { t } = useTranslation('fe_er_common_layout');
  const [forgotPass, setForgotPass] = React.useState({
    open: false,
    render: false,
  }); // ForgotPassword Dialog
  const [openJoinUs, setOpenJoinUs] = React.useState(false); // JoinUs Drawer
  const [openUser, setUserOpen] = React.useState(false); // Logged-in user Drawer
  return (
    <Root className={classes.root}>
      <AppBar position="relative" color="secondary">
        <Toolbar>
          <Container fixed className={classes.container}>
            <DarkButton
              variant="outlined"
              component={NavigatorLink}
              href={PATHS.rentMyMotorcycle()}
              size="medium"
              style={{ borderColor: '#fff' }}>
              {t('fe_er_common_layout:rent_my_bike')}
            </DarkButton>
            <CurrencySelector />
            <CountrySelector />
            {isAuthenticated ? (
              <>
                {appState.settings
                  && appState.settings.show_rental_management_link && (
                    <DarkButton
                      variant="contained"
                      color="secondary"
                      component={NavigatorLink}
                      href={PATHS.agent()}
                      edge="start"
                      size="medium"
                      endIcon={<SwapHoriz className={classes.swapIcon} />}
                      disableElevation>
                      {t('fe_er_common_layout:rental_management')}
                    </DarkButton>
                )}
                {appState.settings
                  && appState.settings.show_current_company
                  && appState.current_company
                  && appState.current_company.name && (
                    <DarkButton
                      variant="contained"
                      color="secondary"
                      edge="start"
                      size="medium"
                      disableElevation
                      className={`square ${classes.userButton} companyButton`}>
                      <div className={`${classes.userInfo} truncate`}>
                        <span className=" company">
                          {t('fe_er_common_layout:company')}
                        </span>
                        <Typography
                          variant="subtitle2"
                          className={`${classes.typography} companyName caption`}>
                          {appState.current_company.name}
                        </Typography>
                      </div>
                    </DarkButton>
                )}
                <DarkButton
                  variant="contained"
                  color="secondary"
                  onClick={() => setUserOpen(true)}
                  edge="start"
                  size="medium"
                  endIcon={<MoreVert />}
                  disableElevation
                  className={classes.userButton}>
                  <Avatar
                    className={classes.avatar}
                    src={
                      appState.current_user.avatar_url || '/imgs/default.png'
                    }
                    alt={`${appState.current_user.first_name} ${appState.current_user.last_name}`}
                  />
                  <div className={`${classes.userInfo} truncate`}>
                    <span className="caption">
                      {t('fe_er_common_layout:welcome_msg')}
                    </span>
                    <Typography
                      variant="subtitle2"
                      className={classes.typography}>
                      {`${appState.current_user.first_name} ${appState.current_user.last_name}`}
                    </Typography>
                  </div>
                </DarkButton>
              </>
            ) : (
              <Button
                className={classes.signInButton}
                variant="contained"
                color="primary"
                aria-label="open drawer"
                onClick={() => setOpenJoinUs(true)}
                edge="start"
                size="medium"
                startIcon={<Person />}
                disableElevation>
                {`${t('fe_er_common_layout:join_us')} / ${t(
                  'fe_er_common_layout:sign_in',
                )}`}
              </Button>
            )}
          </Container>
        </Toolbar>
      </AppBar>
      {!isCrawler && (
        <>
          {isAuthenticated ? (
            <>
              <UserDrawer
                open={openUser}
                setOpen={setUserOpen}
                handleDrawerClose={() => setUserOpen(false)}
                promotedMembership={appState.promoted_membership}
                currentUser={appState.current_user}
                pendingReservationsCount={appState.pending_reservations_count}
              />
            </>
          ) : (
            <>
              <JoinUsDrawer
                open={openJoinUs}
                handleDrawerClose={() => setOpenJoinUs(false)}
                handleForgotPasswordOpen={() => {
                  setForgotPass({ open: true, render: true });
                }}
              />
            </>
          )}
        </>
      )}
      {forgotPass.render && forgotPass.open && (
        <ForgotPasswordDialog
          open={forgotPass.open}
          onClose={() => setForgotPass({
            ...forgotPass,
            open: false,
          })}
        />
      )}
    </Root>
  );
};

export default TopBar;
