/* eslint-disable no-undef */
/* eslint-disable no-alert */
import { useEffect } from 'react';
import axios, { API } from 'lib/config/axios';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import { mapNamedLocaleToLocale } from 'lib/data/localesMap';
import { useDispatch, useSelector } from 'react-redux';

import {
  setAppData,
  setIsCrawler,
  setHrefLangData,
  resetGlobalDataSlice,
  setDeviceType,
  setShowMembership,
  setIsSSR,
  setIsInIframe,
  setAgentLocationInfo,
} from 'redux/features/globalData-slice';
import {
  setError,
  setLoading,
  setShouldRenderPage,
  resetUISlice,
} from 'redux/features/ui-slice';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import useCookies from 'views/common/hooks/useCookies';
import { countries } from 'lib/data/countries';
import { WL_SS_KEY } from 'views/common/components/Logical/MainWidgetWrapper/utils/constants';

const LayoutLogicWrapper = (props) => {
  const {
    layoutData,
    ssrRender,
    hreflangData,
    requireAuth,
    children,
    deviceType,
    serverError,
    options,
  } = props;
  const dispatch = useDispatch();

  const handleErrorResponse = useErrorsHandler();
  const error = useSelector((state) => state.ui.error);
  const appState = useSelector((state) => state.globalData.appState);
  const [cookies] = useCookies();
  const supressNavigationLoader = useSelector(
    (state) => state.ui.supressNavigationLoader,
  );
  const appNavigator = useAppNavigator();
  const addLangAttribute = () => {
    document
      .querySelector('html')
      .setAttribute('lang', mapNamedLocaleToLocale[appNavigator.locale]);
  };
  const redirectIfRequireAuth = (user) => {
    if (
      typeof window !== 'undefined'
      && Object.keys(appState) > 0
      && requireAuth
      && (!user || !user.id)
    ) {
      appNavigator.push('/users/sign_in');
      return true;
    }
    return false;
  };
  const initReduxStore = () => {
    dispatch(resetGlobalDataSlice());
    dispatch(resetUISlice());
  };
  if (typeof window === 'undefined') {
    initReduxStore();
  }

  const setLocationInfoData = () => {
    if (cookies.geo_location_v2) {
      const country = countries.find(
        (countryItem) => countryItem.code === cookies.geo_location_v2.country_code,
      );
      dispatch(
        setAgentLocationInfo({ ...cookies.geo_location_v2, ...country }),
      );
    }
  };

  const setReferralCodeSessionStorage = () => {
    if (appNavigator.query.referral_code) {
      sessionStorage.setItem(WL_SS_KEY, appNavigator.query.referral_code);
    }
  };
  if (
    typeof window !== 'undefined'
    && window.location !== window.parent.location
  ) {
    dispatch(setIsInIframe(window.location !== window.parent.location));
  }

  if (hreflangData) {
    dispatch(setHrefLangData(hreflangData));
  }
  if (layoutData) {
    dispatch(
      setShowMembership(
        !!layoutData.current_ip_country?.default_membership?.id,
      ),
    );
    dispatch(setIsSSR(true));
    dispatch(setAppData(layoutData));
    dispatch(setShouldRenderPage(true));
  } else {
    dispatch(setIsSSR(false));
  }
  if (ssrRender) {
    dispatch(setIsCrawler(true));
  }
  if (deviceType) {
    dispatch(setDeviceType(deviceType));
  }

  if (serverError?.statusCode && !error.statusCode) {
    dispatch(setError(serverError));
  }

  // call Layout Data endpoint
  useEffect(() => {
    if (options?.skipLayoutFromGlobal) return;
    if (!ssrRender && !layoutData) {
      axios
        .get(API.layout)
        .then((res) => {
          if (redirectIfRequireAuth(res.data.current_user)) {
            return;
          }
          dispatch(
            setShowMembership(
              !!res.data.current_ip_country?.default_membership?.id,
            ),
          );

          dispatch(setAppData(res.data));
          if (res.data.authenticity_token) {
            localStorage.setItem(
              'authenticity_token',
              res.data.authenticity_token,
            );
          }
          dispatch(setShouldRenderPage(true));
          window.isMember = res.data.current_user?.is_member;
          window.userId = res.data.current_user?.id;
        })
        .catch((err) => {
          handleErrorResponse(err);
        });
    }
  }, []);

  // Listen for page changes after a navigation or when the query changes
  useEffect(() => {
    appNavigator.events.on('routeChangeComplete', () => {});
    return () => {
      appNavigator.events.off('routeChangeComplete', () => {});
    };
  }, [appNavigator.events]);

  useEffect(() => {
    if (!navigator.cookieEnabled) {
      alert(
        'Cookies are disabled!\nPlease enable cookies from your browser settings for best experience',
      );
    }
  }, []);
  // render LoadingSpinner and adjust lang attribute when navigating between pages
  useEffect(() => {
    const handlePageChangeSpinner = () => {
      if (error.statusCode) {
        dispatch(setError({ statusCode: false }));
      }
      addLangAttribute();
      if (!supressNavigationLoader) {
        dispatch(setLoading(true));
      } else {
        dispatch(setLoading(false));
      }
    };
    appNavigator.events.on('routeChangeStart', handlePageChangeSpinner);
    appNavigator.events.on('routeChangeComplete', () => {
      addLangAttribute();
      dispatch(setLoading(false));
    });
    appNavigator.events.on('routeChangeError', () => {
      addLangAttribute();
      dispatch(setLoading(false));
    });
    return () => {
      appNavigator.events.off('routeChangeStart', handlePageChangeSpinner);
    };
  }, [error, supressNavigationLoader]);

  // adjust lang attribute when changing locales
  useEffect(() => {
    addLangAttribute();
  }, [appNavigator.locale]);

  // If the page require authentication, redirect to home page
  useEffect(() => {
    redirectIfRequireAuth();
  });

  useEffect(() => {
    setLocationInfoData();
    setReferralCodeSessionStorage();
  }, []);

  return children;
};

export default LayoutLogicWrapper;
