export const isSafari = () => {
  if (typeof window !== 'undefined' && 'navigator' in window) {
    if (
      navigator.userAgent.match(/safari/i)
      && !navigator.userAgent.match(/chrome/i)
      && !navigator.userAgent.match(/crios/i)
      && !navigator.userAgent.match(/fxios/i)
      && !navigator.userAgent.match(/Opera|OPT\//)
    ) {
      return true;
    }
  }
  return false;
};
