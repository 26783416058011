import React from 'react';
import Link from 'next/link';
import MuiLink from '@mui/material/Link';
import { CURRENT_APP } from 'lib/navigation/apps';
import useAppNavigator from 'views/common/hooks/useAppNavigator';

const NavigatorLink = (props, ref) => {
  const {
    href,
    refresh,
    locale,
    children,
    mapUrl,
    translate = true,
    ...restOfProps
  } = props;
  const appNavigator = useAppNavigator();

  if (mapUrl) {
    const getMapUrl = () => {
      if (typeof window !== 'undefined' && 'navigator' in window) {
        if (
          /* if we're on iOS, open in Apple Maps */
          navigator.platform.indexOf('iPhone') != -1
          || navigator.platform.indexOf('iPod') != -1
          || navigator.platform.indexOf('iPad') != -1
        ) {
          return `maps://${mapUrl}`;
        }
        return `https://${mapUrl}`;
      }
      return `https://${mapUrl}`;
    };
    return (
      <MuiLink
        href={getMapUrl()}
        ref={ref}
        rel="noopener"
        underline="none"
        {...restOfProps}>
        {children}
      </MuiLink>
    );
  }
  const getNoOpener = () => {
    const noOpener = typeof href === 'string'
      ? href.startsWith('http')
      : href?.url?.startsWith('http');
    return noOpener ? { rel: 'noopener' } : {};
  };
  const link = href
    && appNavigator.appendQuery(
      appNavigator.getUrl(locale, href, translate),
      href?.query,
    );
  if (typeof href === 'object' && !link.startsWith('http')) {
    if (href.app === CURRENT_APP) {
      return (
        <>
          <Link
            {...restOfProps}
            prefetch={false}
            href={link}
            passHref
            locale={locale}>
            <MuiLink ref={ref} underline="none" {...restOfProps}>
              {children}
            </MuiLink>
          </Link>
        </>
      );
    }
  }
  return (
    <>
      <MuiLink
        href={link}
        ref={ref}
        underline="none"
        {...getNoOpener()}
        {...restOfProps}>
        {children}
      </MuiLink>
    </>
  );
};

export default React.memo(React.forwardRef(NavigatorLink));
