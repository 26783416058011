/* eslint-disable no-restricted-syntax */
import { useRouter } from 'next/router';
import { CURRENT_APP, RM_FE } from 'lib/navigation/apps';
import {
  mapLocaleToSubdomain,
  namedLocales,
  subdomainLocalesArr,
} from 'lib/data/localesMap';
import { useTranslation } from 'next-i18next';
import filterQuerySegments from 'lib/utils/filterQuerySegments';
import generateRentalNavigationUrl from 'lib/utils/generateRentalNavigationUrl';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const { API_PROTOCOL, EN_DOMAIN } = envVars;

const shallowClone = (obj) => Object.create(
  Object.getPrototypeOf(obj),
  Object.getOwnPropertyDescriptors(obj),
);

const useAppNavigator = () => {
  let urlObject = {};
  const router = useRouter();
  const { t } = useTranslation('routes');

  const getUrl = (locale, href, translate = true) => {
    const isObject = typeof href === 'object';
    let linkUrl = isObject ? href.url : href;
    if (href.app === RM_FE) {
      linkUrl = generateRentalNavigationUrl(href.url, router.locale);
      return linkUrl;
    }
    // handle home page case so that we don't make an extra 308 call
    if (linkUrl === '/') {
      if (!subdomainLocalesArr.includes(router.locale)) {
        linkUrl = '/home_en';
      }
    }
    const linkUrlSegments = linkUrl.split('/').slice(1);
    let translatedLink = '';
    const isBE = (isObject && href.app !== CURRENT_APP) || !isObject;
    if (linkUrl.startsWith('http')) return linkUrl;
    if (locale) {
      if (router.locale !== locale) {
        if (mapLocaleToSubdomain[router.locale]) {
          if (mapLocaleToSubdomain[locale]) {
            translatedLink = `${API_PROTOCOL}://${mapLocaleToSubdomain[locale]}`;
          } else {
            translatedLink = `${API_PROTOCOL}://${EN_DOMAIN}/${locale}`;
          }
        }
      }
    } else if (isBE) {
      if (mapLocaleToSubdomain[router.locale]) {
        translatedLink = `${API_PROTOCOL}://${
          mapLocaleToSubdomain[router.locale]
        }`;
      } else {
        const addedLocale = router.locale === 'en' ? '' : `/${router.locale}`;
        translatedLink = `${API_PROTOCOL}://${EN_DOMAIN}${addedLocale}`;
      }
    }
    for (const segment of linkUrlSegments) {
      translatedLink += `/${translate ? t(segment) : segment}`;
    }
    if (!isBE) {
      namedLocales.forEach((lang) => {
        if (translatedLink.split('/')[1] === lang) {
          translatedLink = `/${lang}${translatedLink}`;
        }
      });
    }
    return translatedLink;
  };

  const appendQuery = (url, query) => {
    let queryString = '';
    if (query) {
      queryString = '?';
      Object.entries(filterQuerySegments(query)).forEach(([key, value]) => {
        if (queryString !== '?') queryString += '&';
        queryString += `${key}=${value}`;
      });
      queryString = queryString === '?' ? '' : queryString;
    }
    return url + queryString;
  };
  const push = (path, as, opts) => {
    if (typeof path === 'string') urlObject.url = path;
    if (typeof path === 'object') urlObject = path;
    const locale = opts?.locale;
    let url = getUrl(locale, urlObject, opts?.translate);
    url = appendQuery(url, path.query);
    if (urlObject.app === CURRENT_APP && !url.startsWith('http')) {
      router.push(url, as, opts);
      return null;
    }
    window.location.href = url;
    return null;
  };

  const newRouter = shallowClone(router);
  newRouter.push = push;
  newRouter.appendQuery = appendQuery;
  newRouter.getUrl = getUrl;
  return newRouter;
};
export default useAppNavigator;
