/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { notifyAirbrake } from 'lib/config/airbrake';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    notifyAirbrake(`Error Boundary : ${error}`);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return null;
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
