import React, { useState, useMemo, useEffect } from 'react';
import {
  Typography, Button, Grid, Box,
} from '@mui/material';
import StyledBox, {
  StyledImgBox,
} from 'views/common/components/UI/AppAdMobile/AppAdMobile.styles';
import { useTranslation } from 'next-i18next';
import CloseIcon from '@mui/icons-material/Close';
import Typography500 from 'views/common/components/UI/Typography500';
import { isSafari } from 'lib/utils/isSafari';
import { detectDeviceOS, osTypes } from 'lib/utils/detectDeviceOS';
import PATHS from 'lib/navigation/paths';
import {
  setLocalStorageKeyeWithTTL,
  // checkTTLExpiration,
} from 'lib/utils/localStroage';
import { useSelector } from 'react-redux';

const logoImage = {
  src: '/imgs/logo.webp',
  alt: 'Eaglerider Logo',
};

const AppAdMobile = (props) => {
  const { localStorageName, localStorageValue } = props;
  const { t } = useTranslation('fe_er_common_app_ad_mobile');
  const [open, setOpen] = useState(true);
  const isSafariBrowser = useMemo(() => isSafari(), []);
  const { appState } = useSelector((state) => state.globalData);
  const osType = useMemo(() => detectDeviceOS(), []);
  const getStoreHref = () => {
    switch (osType) {
      case osTypes.ios:
        return PATHS.app.ios;
      case osTypes.android:
        return PATHS.app.android;
      default:
        return PATHS.app.playStore;
    }
  };
  const localStorageTtl = +(
    appState?.settings?.mobile_banner_ad_hidden_for || null
  );

  const handleCloseAdClick = () => {
    setOpen(false);
    setLocalStorageKeyeWithTTL(
      localStorageName,
      localStorageValue,
      localStorageTtl,
    );
  };

  const handleGetBtnClick = () => {
    setLocalStorageKeyeWithTTL(
      localStorageName,
      localStorageValue,
      localStorageTtl,
    );
  };

  useEffect(() => {
    //  setOpen(!checkTTLExpiration(localStorageName));
  }, []);

  return (
    !isSafariBrowser && (
      <StyledBox>
        {!!open && (
          <Grid container className="AAMContainer" p={1}>
            <Grid container item className="AAMImageText" columnSpacing="1">
              <Grid item>
                <CloseIcon fontSize="small" onClick={handleCloseAdClick} />
              </Grid>
              <Grid item>
                <Box className="AAMImg">
                  <StyledImgBox
                    image={logoImage}
                    objectFit="contain"
                    priority
                  />
                </Box>
              </Grid>
              <Grid item>
                <Typography500 className="AAMTitle" component="p">
                  {t('fe_er_common_app_ad_mobile:eaglerider_motorcylce')}
                </Typography500>
                <Typography className="AAMDesc" component="p">
                  {t('fe_er_common_app_ad_mobile:vehical_models')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                size="small"
                href={getStoreHref()}
                className="AAMGetBtn"
                disableElevation
                onClick={handleGetBtnClick}>
                {t('fe_er_common_app_ad_mobile:get')}
              </Button>
            </Grid>
          </Grid>
        )}
      </StyledBox>
    )
  );
};

export { AppAdMobile };
