/* eslint-disable prefer-destructuring */
import { mapERLocaleToESLocale } from 'lib/data/localesMap';
import { FE, BE, RM_FE } from 'lib/navigation/apps';

const { envVars } = require('next/config').default().publicRuntimeConfig;

// name : (query)=>({url: '' , app: FE , query})

const PATHS = {
  sameh: (query) => ({ url: '/sameh', app: FE, query }),
  home: (query) => ({ url: '/', app: FE, query }),
  homeLocalized: (query) => ({ url: '/home_en', app: FE, query }),
  agent: (query) => ({ url: '/', app: RM_FE, query }),
  privacyPolicy: (query) => ({ url: '/privacy-policy', app: BE, query }),
  urlSlugs: (slug, query) => ({ url: `/${slug}`, app: FE, query }),
  twoSlugs: (slug1, slug2, query) => ({
    url: `/${slug1}/${slug2}`,
    app: FE,
    query,
  }),
  threeSlugs: (slug1, slug2, slug3, query) => ({
    url: `/${slug1}/${slug2}/${slug3}`,
    app: FE,
    query,
  }),
  geoSlug: (...args) => {
    let slugs;
    let query;
    if (typeof args[0] === 'string') {
      if (typeof args[args.length - 1] === 'string') {
        slugs = args;
      } else slugs = args.slice(0, args.length - 1);
    } else if (Array.isArray(args[0])) {
      slugs = args[0];
      query = args[1];
    }
    let url = '';
    slugs.forEach((slug) => {
      if (slug) url += `/${encodeURIComponent(decodeURIComponent(slug))}`; // decode first in case it comes encoded to avoid encoding twice
    });
    if (url === '') {
      url = '/motorcycle-rental';
    }
    return {
      url,
      app: FE,
      query,
    };
  },
  faqs: {
    index: (query) => ({ url: '/faqs', app: FE, query }),
    withSlug: (slug, query) => ({
      url: `/faqs/${slug}`,
      app: FE,
      query,
    }),
  },
  user: {
    membership: (query) => ({ url: '/user/membership', app: FE, query }),
    switchToYearly: (query) => ({
      url: '/user/membership/switch-to-yearly',
      app: FE,
      query,
    }),
    creditCards: (query) => ({ url: '/user/payment_methods', app: FE, query }),
    newCreditCards: (query) => ({
      url: '/user/credit_cards/new',
      app: FE,
      query,
    }),
    dashboard: (query) => ({
      url: '/user/account',
      app: FE,
      query,
    }),
    vehicleReservations: (query) => ({
      url: '/user/vehicle_reservations',
      app: FE,
      query,
    }),
    verifyEmail: (query) => ({
      url: '/user/verify_email',
      app: FE,
      query,
    }),
    personalInfo: (query) => ({
      url: '/user/personal_information',
      app: FE,
      query,
    }),
    vehicles: (query) => ({
      url: '/user/vehicles',
      app: BE,
      query,
    }),
    gears: (query) => ({
      url: '/user/gears',
      app: BE,
      query,
    }),
    reservations: (query) => ({
      url: '/user/reservations',
      app: FE,
      query,
    }),
    makeMoney: (query) => ({
      url: '/user/make-money',
      app: FE,
      query,
    }),
    rewards: (query) => ({
      url: '/user/rewards',
      app: FE,
      query,
    }),
    editReservation: (reservationIdentifier, query) => ({
      url: `/user/reservations/${reservationIdentifier}/new_edit`,
      app: FE,
      query,
    }),
  },
  users: {
    signUp: (query) => ({
      url: '/users/sign_up',
      app: FE,
      query,
    }),
    signIn: (query) => ({
      url: '/users/sign_in',
      app: FE,
      query,
    }),
    signOut: (query) => ({
      url: '/users/sign_out',
      app: BE,
      query,
    }),
    resetPassword: (query) => ({
      url: '/users/password/edit',
      app: FE,
      query,
    }),
  },
  owner: {
    vehicleReservations: (query) => ({
      url: '/owner/vehicle_reservations',
      app: BE,
      query,
    }),
    earningsAndPayouts: (query) => ({
      url: '/owner/earnings_and_payouts',
      app: BE,
      query,
    }),
  },
  membership: {
    termsAndConditions: (query) => ({
      url: '/membership/terms-and-conditions',
      app: FE,
      query,
    }),
    index: (query) => ({
      url: '/membership',
      app: BE,
      query,
    }),
  },
  vrs: {
    checkIn: (identifier, query) => ({
      url: `/vrs/${identifier}/checkin`,
      app: FE,
      query,
    }),
    pickupContract: (identifier, query) => ({
      url: `/vrs/${identifier}/pickup_contract`,
      app: FE,
      query,
    }),
    dropoffReceipt: (identifier, query) => ({
      url: `/vrs/${identifier}/dropoff_receipt`,
      app: FE,
      query,
    }),
  },
  siteIndex: (query) => ({ url: '/siteindex', app: FE, query }),
  siteIndexPlace: (place, query) => ({
    url: `/siteindex/${place}`,
    app: FE,
    query,
  }),

  geo: (query) => ({ url: '/geo', app: FE, query }),
  vechicleReservation: {
    index: (id, query) => ({
      url: `/vehicle_reservations/${id}`,
      app: FE,
      query,
    }),
    pickup: (id, query) => ({
      url: `/vehicle_reservations/${id}/pickup`,
      app: BE,
      query,
    }),
    dropoffStart: (id, query) => ({
      url: `/vehicle_reservations/${id}/dropoff/start`,
      app: BE,
      query,
    }),
    dropoffRiderRating: (pID, query) => ({
      url: `/vehicle_reservations/${pID}/dropoff/rider_rating`,
      app: BE,
      query,
    }),
    dropoffRiderAllSet: (pID, query) => ({
      url: `/vehicle_reservations/${pID}/dropoff/rider_all_set`,
      app: BE,
      query,
    }),
    cancellation: (pID, query) => ({
      url: `/vehicle_reservations/${pID}/dropoff/cancellation`,
      app: BE,
      query,
    }),
    reviewCancellation: (id, query) => ({
      url: `/vehicle_reservations/${id}/review_cancellation`,
      app: BE,
      query,
    }),
    reportAnIssue: (pID, query) => ({
      url: `/vehicle_reservations/${pID}/report-an-issue`,
      app: BE,
      query,
    }),
  },
  rentMyMotorcycle: (query) => ({ url: '/rent-my-motorcycle', app: FE, query }),
  motorcycleRentalGiftCards: {
    buy: (query) => ({
      url: '/motorcycle-rental-gift-cards/buy',
      app: FE,
      query,
    }),
    index: (query) => ({
      url: '/motorcycle-rental-gift-cards',
      app: FE,
      query,
    }),
  },
  motorcycleRental: (query) => ({
    url: '/motorcycle-rental',
    app: FE,
    query,
  }),
  motorcycleDeals: {
    index: (query) => ({
      url: '/motorcycle-deals',
      app: FE,
      query,
    }),
    slug: (slug, query) => ({
      url: `/motorcycle-deals/${slug}`,
      app: FE,
      query,
    }),
    typeLocationslug: (typeSlug, locationSlug, query) => ({
      url: `/motorcycle-deals/${typeSlug}/${locationSlug}`,
      app: FE,
      query,
    }),
  },
  motorcycleEvents: {
    index: (query) => ({
      url: '/motorcycle-events',
      app: FE,
      query,
    }),
    slug: (slug, query) => ({
      url: `/motorcycle-events/${slug}`,
      app: FE,
      query,
    }),
    reviews: (slug, query) => ({
      url: `/motorcycle-events/${slug}/reviews`,
      app: FE,
      query,
    }),
  },
  motorcycleRides: {
    index: (query) => ({
      url: '/motorcycle-rides',
      app: FE,
      query,
    }),
    slug: (slug, query) => ({
      url: `/motorcycle-rides/${slug}`,
      app: FE,
      query,
    }),
    reviews: (slug, query) => ({
      url: `/motorcycle-rides/${slug}/reviews`,
      app: FE,
      query,
    }),
  },
  locations: (query) => ({
    url: '/locations',
    app: FE,
    query,
  }),
  clubLocations: (query) => ({
    url: '/club-locations',
    app: FE,
    query,
  }),
  locationReviews: (locationSlug, query) => ({
    url: `/${locationSlug}/reviews`,
    app: FE,
    query,
  }),
  locationSlug: (locationSlug, query) => ({
    url: `/${locationSlug}`,
    app: FE,
    query,
  }),
  checkIn: (query) => ({
    url: '/check-in',
    app: FE,
    query,
  }),
  motorcycleTours: {
    index: (query) => ({
      url: '/motorcycle-tours',
      app: FE,
      query,
    }),
    slug: (locationSlug, query) => ({
      url: `/motorcycle-tours/${locationSlug}`,
      app: FE,
      query,
    }),
    listing: (query) => ({
      url: '/motorcycle-tours',
      app: FE,
      query: { q: '', ...query },
    }),
  },
  filteredMotorcycleTours: (slug, query) => ({
    url: `/motorcycle-tours/${slug}`,
    app: BE,
    query,
  }),
  motorcycleClubGiftCards: {
    buy: (query) => ({
      url: '/motorcycle-club-gift-cards/buy',
      app: FE,
      query,
    }),
  },
  motorcycleClub: {
    index: (query) => ({ url: '/motorcycle-subscription', app: FE, query }),
    checkout: (query) => ({ url: '/motorcycle-club/checkout', app: FE, query }),
    signIn: (query) => ({ url: '/motorcycle-club/sign-in', app: FE, query }),
    signUp: (query) => ({ url: '/motorcycle-club/sign-up', app: FE, query }),
    upgrade: (query) => ({ url: '/motorcycle-club/upgrade', app: FE, query }),
    slug: (slug, query) => ({
      url: `/motorcycle-subscription/${slug}`,
      app: FE,
      query,
    }),
  },
  auth: {
    facebook: (query) => ({ url: '/auth/facebook', app: FE, query }),
    google: (query) => ({ url: '/auth/google', app: FE, query }),
  },
  personalBikeReservation: {
    checkout: (reservationId, query) => ({
      url: `/reservation/${reservationId}/checkout`,
      app: FE,
      query,
    }),
  },
  rentals: {
    index: (query) => ({
      url: '/rentals',
      app: FE,
      query,
    }),
    slug: (slug, query) => ({
      url: `/rentals/${slug}`,
      app: FE,
      query,
    }),
    terms: (query) => ({
      url: '/rentals/gift-cards-terms-and-conditions',
      app: FE,
      query,
    }),
    conditions: (query) => ({
      url: '/rentals/gift-cards-terms-and-conditions',
      app: BE,
      query,
    }),
    rentalTerms: (query) => ({
      url: '/rentals/rental-terms-and-conditions',
      app: FE,
      query,
    }),
    locationMotorcyleRental: (locationSlug, rentalSlug, query) => ({
      url: `/${locationSlug}/${rentalSlug}`,
      app: FE,
      translate: false,
      query,
    }),
    locationRental: (locationSlug, query) => ({
      url: `/${locationSlug}/rental`,
      app: FE,
      query,
    }),
    slugRentals: (slug, query) => ({
      url: `/${slug}`,
      app: FE,
      query,
    }),
  },
  reservationPath: {
    pickupAndDropoff: (reservationId, query) => ({
      url: '/reservation/pickup_and_dropoff',
      app: FE,
      query,
    }),
    riders: (query) => ({
      url: '/reservation/rider-names',
      app: FE,
      query,
    }),
    vehicles: (query) => ({
      url: '/reservation/motorcycles',
      app: FE,
      query,
    }),
    extras: (query) => ({
      url: '/reservation/extras',
      app: FE,
      query,
    }),
    checkout: (query) => ({
      url: '/reservation/checkout',
      app: FE,
      query,
    }),
    paymentRedirect: (query) => ({
      url: '/reservation/payment_redirect',
      app: FE,
      query,
    }),
    complete: (query) => ({
      url: '/reservation/confirmation',
      app: FE,
      query,
    }),
    destinations: (query) => ({
      url: '/reservation/destinations',
      app: BE,
      query,
    }),
    confirmation: (reservationId, query) => ({
      url: `/reservations/${reservationId}/confirmation`,
      app: FE,
      query,
    }),
    continueReservation: (query) => ({
      url: '/reservations/resume',
      app: FE,
      query,
    }),
  },
  bikeHotelTerms: (query) => ({
    url: '/motorcycle-vacation/bike-hotel-terms-and-conditions',
    app: BE,
    query,
  }),
  contactUs: (query) => ({ url: '/contact-us', app: FE, query }),
  aiChat: (query) => ({ url: '/ai-chat', app: FE, query }),
  tour: {
    index: (tourTypeSlug, tourSlug, query) => ({
      url: `/${encodeURIComponent(tourTypeSlug)}/${encodeURIComponent(
        tourSlug,
      )}`,
      app: FE,
      query,
    }),
    reviews: (tourUrl, query) => ({
      url: `${tourUrl}/reviews`,
      app: FE,
      query,
    }),
    guidedTourIndex: (query) => ({
      url: '/guided-motorcycle-tours',
      app: FE,
      query,
    }),
    guidedTourTerms: (query) => ({
      url: '/motorcycle-tours/guided-tour-terms-and-conditions',
      app: FE,
      query,
    }),
    selfTourIndex: (query) => ({
      url: '/self-drive-motorcycle-tours',
      app: FE,
      query,
    }),
    selfDriveTourTerms: (query) => ({
      url: '/motorcycle-tours/self-drive-tour-terms-and-conditions',
      app: FE,
      query,
    }),
    offRoadTourTerms: (query) => ({
      url: '/off-road-tours/off-road-tours-terms-and-conditions',
      app: FE,
      query,
    }),
    roadTripTerms: (query) => ({
      url: '/motorcycle-tours/road-trip-terms-and-conditions',
      app: BE,
      query,
    }),
    tourType: (tourTypeSlug, query) => ({
      url: `/${tourTypeSlug}`,
      app: BE,
      query,
    }),
    guidedTourReviews: (tourSlug, query) => ({
      url: `/guided-motorcycle-tours/${tourSlug}/reviews`,
      app: FE,
      query,
    }),
    offRoadTourReviews: (tourSlug, query) => ({
      url: `/off-road-tours/${tourSlug}/reviews`,
      app: FE,
      query,
    }),
    selfTourReviews: (tourSlug, query) => ({
      url: `/self-drive-motorcycle-tours/${tourSlug}/reviews`,
      app: FE,
      query,
    }),
    TourReviews: (tourSlug, query) => ({
      url: `/motorcycle-tours/${tourSlug}/reviews`,
      app: FE,
      query,
    }),
  },
  motorcycleTrips: {
    index: (query) => ({
      url: '/motorcycle-trips',
      app: FE,
      query,
    }),
    slug: (slug, query) => ({
      url: `/${slug}`,
      app: FE,
      query,
    }),
  },
  guidedOffroadTour: {
    index: (query) => ({
      url: '/off-road-tours',
      app: FE,
      query,
    }),
    slug: (tourSlug, query) => ({
      url: `/off-road-tours/${tourSlug}`,
      app: FE,
      query,
    }),
    offroadLATour: (query) => ({
      url: '/off-road-tours/las-vegas-mojave-desert-off-road-tour',
      app: FE,
      query,
    }),
  },
  guidedTour: (tourSlug, query) => ({
    url: `/guided-motorcycle-tours/${tourSlug}`,
    app: FE,
    query,
  }),
  guidedTourIndex: (query) => ({
    url: '/guided-motorcycle-tours',
    app: FE,
    query,
  }),
  selfDriveTourIndex: (query) => ({
    url: '/self-drive-motorcycle-tours',
    app: FE,
    query,
  }),
  selfDriveTour: (tourSlug, query) => ({
    url: `/self-drive-motorcycle-tours/${tourSlug}`,
    app: FE,
    query,
  }),
  experience: (tourSlug, query) => ({ url: `/${tourSlug}`, app: BE, query }),
  vehicleUrl: (makeSlug, modelSlug, geoSlug, id, query) => ({
    url: `/${makeSlug}/${modelSlug}/${geoSlug}/${id}`,
    app: BE,
    query,
  }),
  checkInConfirmation: (query) => ({
    url: '/motorcycle-check-in/confirmation',
    app: FE,
    query,
  }),
  reviews: (query) => ({
    url: '/reviews',
    app: FE,
    query,
  }),
  information: (query) => ({
    url: '/information',
    app: FE,
    query,
  }),
  rentalsSlug: (rentalSlug, query) => ({
    url: `/${rentalSlug}`,
    app: BE,
    query,
  }),
  vehicleModel: {
    index: (locationSlug, vehicleMakeSlug, vehicleModelSlug, query) => {
      let url = '';
      const slugs = [locationSlug, vehicleMakeSlug, vehicleModelSlug];
      slugs.forEach((slug) => {
        if (slug) url += `/${encodeURIComponent(decodeURIComponent(slug))}`; // decode first in case it comes encoded to avoid encoding twice
      });
      return {
        url,
        app: FE,
        query,
      };
    },
    reviews: (vehicleMakeSlug, slug, query) => ({
      url: `/${vehicleMakeSlug}/${slug}/reviews`,
      app: FE,
      query,
    }),
  },
  motorcycleRentalsSlug: (placeSlug, query) => ({
    url: `/motorcycle-rental/${placeSlug}`,
    app: FE,
    query,
  }),
  paymentOptions: (query) => ({
    url: '/payment-options',
    app: FE,
    query,
  }),
  history: (query) => ({
    url: '/about-us/history',
    app: FE,
    query,
  }),
  insurance: (query) => ({
    url: '/motorcycle-rental-insurance',
    app: FE,
    query,
  }),
  press: (query) => ({
    url: '/about-us/press',
    app: FE,
    query,
  }),
  eagleshare: (slug, locale = 'en') => {
    let url = envVars.ES_DOMAIN;
    if (locale !== 'en') {
      url += `/${mapERLocaleToESLocale[locale]}`;
    }
    url += slug;
    return {
      url,
      app: BE,
    };
  },
  affiliateProgram: (query) => ({
    url: '/affiliate-program',
    app: FE,
    query,
  }),
  hotelpartners: (query) => ({
    url: '/hotelpartners',
    app: FE,
    query,
  }),
  app: {
    ios: 'https://apple.co/3Uoosuh',
    android:
      'intent://eaglerider.com/#Intent;scheme=comeaglerider;package=com.eaglerider;end',
    playStore: 'https://play.google.com/store/apps/details?id=com.eaglerider',
  },
  downloadOurApp: (query) => ({
    url: 'download-our-app',
    app: FE,
    query,
  }),
  motorcycleTouring: {
    index: (query) => ({
      url: '/motorcycle-touring',
      app: FE,
      query,
    }),
    slug: (slug, query) => ({
      url: `/motorcycle-touring/${slug}`,
      app: FE,
      query,
    }),
  },
  franchise: {
    becomeFranchiseDoc: () => 'https://docs.google.com/forms/d/1AF2DrjrnVam5RqxBqlETEnOt9btUjTY_sMN38LRnVpA/viewform?edit_requested=true',
    index: (query) => ({
      url: '/franchise',
      app: FE,
      query,
    }),
    becomingFranchise: (query) => ({
      url: '/franchise/becoming-a-franchise',
      app: FE,
      query,
    }),
    support: (query) => ({
      url: ' /franchise/support',
      app: FE,
      query,
    }),
    aboutUs: (query) => ({
      url: '/franchise/about-us',
      app: FE,
      query,
    }),
    rebranding: (query) => ({
      url: '/franchise/re-branding',
      app: FE,
      query,
    }),
    marketOpportunities: (query) => ({
      url: '/franchise/potential-markets',
      app: FE,
      query,
    }),
    international: (query) => ({
      url: '/franchise/international',
      app: FE,
      query,
    }),
  },
  aboutUs: (query) => ({
    url: '/about-us',
    app: FE,
    query,
  }),
  driverLicense: (query) => ({
    url: '/motorcycle-rental-license-requirements',
    app: FE,
    query,
  }),
  rewards: (query) => ({
    url: '/rewards',
    app: FE,
    query,
  }),
  lrr: (query) => ({
    url: '/lrr',
    app: FE,
    query,
  }),
  rewardsTermsAndConditions: (query) => ({
    url: '/rewards/eaglerider-rewards-terms-and-conditions',
    app: FE,
    query,
  }),
  experienceReview: {
    submit: (token, query) => ({
      url: `/experience-review/${token}`,
      app: FE,
      query,
    }),
    confirmation: (query) => ({
      url: '/experience-review/success-confirmation',
      app: FE,
      query,
    }),
  },
  whitelabelWidget: (query) => ({
    url: '/reservation_widget/new',
    app: FE,
    query,
  }),
  whitelabelWidgetScripts: (query) => ({
    url: '/reservation_widget/load_white_label.js',
    app: FE,
    query,
  }),
};

export default PATHS;
