export const BE_DATE_FORMAT = 'DD-MM-YYYY';
export const RESERVATION_PATH_URLS = [
  '/reservation/checkout',
  '/reservation/rider-names',
  '/reservation/motorcycles',
  '/reservation/extras',
  '/reservation/confirmation',
];
export const SLUGS_TYPES = {
  event: 'event',
  event_type: 'event_type',
  location: 'location',
  deal: 'deal',
  deal_type_category: 'deal_type_category',
  guided_tour: 'guided',
  guided_offroad_tour: 'guided_offroad',
  self_drive: 'self_drive',
  tour_type: 'tour_type',
  country: 'country',
  region: 'region',
  market: 'market',
  experience_page: 'experience_page',
  attraction: 'attraction',
  vehicle_model: 'vehicle_model',
  vehicle_make: 'vehicle_make',
  vehicle_type: 'vehicle_type',
  airport: 'airport',
  geography: 'geography',
  zipcode: 'zipcode',
};

export const DEAL_TYPES = {
  DAILY_RATE: 'daily_rate',
  ONE_WAY_SPECIAL: 'one_way_special',
  BUY_ONE_DAY_GET_ONE: 'buy_one_day_get_one',
  DISCOUNT_AMOUNT: 'discount_amount',
  TOUR: 'tour',
  DISCOUNT_PERCENT: 'discount_percent',
  PACKAGE_PRICE: 'package_price',
};
export const REVIEW_SUBMIT = 1;
export const VOTE_SUBMIT = 2;
export const STAR_RATING = 'star_rating';

export const TOURS_TYPES = {
  self_drive: 'self-drive-motorcycle-tours',
  guided: 'guided-motorcycle-tours',
};

export const FAQS_TYPES_PATHS = {
  rental: 'motorcycle-rentals',
  self_drive: 'self-drive-motorcycle-tours',
  guided: 'guided-motorcycle-tours',
  membership: 'membership',
  membership_e_gift_cards: 'membership-gift-cards',
  e_gift_cards: 'rentals-and-tours-gift-cards',
  rewards: 'rewards',
  offroad: 'offroad',
};
export const FAQS_TYPES = {
  rental: 'rental',
  self_drive: 'self_drive',
  guided: 'guided',
  membership: 'membership',
  membership_e_gift_cards: 'membership_e_gift_cards',
  e_gift_cards: 'e_gift_cards',
};
export const BLOGS_SORT = {
  descending: 'desc',
  ascending: 'asc',
};

export const EXTERNAL_PAYMENT_STATUS = {
  success: 'succeeded',
  failure: 'failed',
  in_process: 'in_process',
  pending_confirmation: 'pending_confirmation',
};

export const GA_EVENT_CATEGORY = {
  home: 'Homepage',
  geo: 'Rental SRP',
  vehicle_model: 'Rental PDP',
  tours_listing_index: 'Tour SRP',
  tours_listing_location: 'Location Tour SRP',
  tour: 'Tour PDP',
  contact_us: 'Support',
  reservation_path: 'Checkout',
  location: 'Location Page',
  other: 'Other',
};
export const LIMIT_AMOUNT_WC = '$500';

export const PRODUCTS_NAME = {
  bike: 'Bike',
  guided_tour: 'Guided Tour',
  self_drive_tour: 'Self Drive Tour',
  offroad_guided_tour: 'Offroad Guided Tour',
};

export const TOUR_TYPES = {
  GUIDED: 'guided',
  SELF_DRIVE: 'self_drive',
  GUIDED_OFFROAD: 'guided_offroad',
  ALL: 'all',
};
