import React from 'react';
import Sitemap from 'views/common/components/Logical/Layout/Footer/Sitemap';
import Copyright from 'views/common/components/Logical/Layout/Footer/Copyright';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import Cookies from 'views/common/components/Logical/Layout/Footer/Cookies';
import { LinksFooter } from 'views/common/components/Logical/Layout/Footer';

const Revzilla = dynamic(() => import('views/common/components/Logical/Layout/Footer/Revzilla'));
const NeedHelp = dynamic(() => import('views/common/components/Logical/Layout/Footer/NeedHelp'));

const Footer = (props) => {
  const { showNeedHelpComponent, showCookieNotification } = props;
  const appState = useSelector((state) => state.globalData.appState);
  return (
    <footer>
      {showNeedHelpComponent && <NeedHelp />}
      <Sitemap />
      <LinksFooter />
      <Copyright />
      {appState?.settings?.show_revzilla && <Revzilla />}
      {appState?.settings?.show_cookie_notification
        && showCookieNotification && <Cookies />}
    </footer>
  );
};

export default Footer;
