import { useState, useEffect } from 'react';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import { useSelector } from 'react-redux';
import PATHS from 'lib/navigation/paths';

const desktopMenuItems = [
  {
    id: 'logo',
    text: 'fe_er_common_layout:EagleRider',
    href: PATHS.home(),
    src: '/imgs/logo.webp',
  },
  {
    id: 'rentals',
    text: 'fe_er_common_layout:common.rentals',
    href: PATHS.motorcycleRental(),
    submenu: {
      heading: 'fe_er_common_layout:common.top_motorcycle_rentals',
      items: [], // Fetched from /layout
    },
  },
  {
    id: 'packages_and_tours',
    text: 'fe_er_common_layout:navigation.packages_tours',
    href: PATHS.motorcycleTours.index(),
    submenu: {
      heading: null,
      items: [
        [
          {
            text: 'fe_er_common_layout:common.guided_motorcycle_tours',
            href: PATHS.tour.guidedTourIndex(),
          },
          {
            text: 'fe_er_common_layout:navigation.all_tours',
            href: PATHS.motorcycleTours.index(),
          },
        ],
        [
          {
            text: 'fe_er_common_layout:self_motorcycle_tours',
            href: PATHS.tour.selfTourIndex(),
          },
          {
            text: 'fe_er_common_layout:navigation.see_popular_routes',
            href: PATHS.motorcycleTrips.index(),
          },
        ],
        [
          {
            id: 'packages_and_tours_custom_trip',
            text: 'fe_er_common_layout:navigation.custom_trips',
            href: { url: '/javascript:;' },
          },
        ],
      ],
    },
  },
  {
    id: 'off_road',
    text: 'fe_er_common_layout:navigation.off_road',
    href: PATHS.guidedOffroadTour.index(),
    supText: 'fe_er_common_layout:navigation.new_item',
  },
  {
    id: 'deals',
    text: 'fe_er_common_layout:navigation.deals',
    href: PATHS.motorcycleDeals.index(),
  },
  {
    id: 'events',
    text: 'fe_er_common_layout:navigation.events',
    href: PATHS.motorcycleEvents.index(),
  },
  {
    id: 'locations',
    text: 'fe_er_common_layout:navigation.locations',
    href: PATHS.locations(),
  },
  {
    id: 'check_in',
    text: 'fe_er_common_layout:navigation.check_in',
    href: PATHS.checkIn(),
  },
  {
    id: 'eagleshare',
    text: 'fe_er_common_layout:navigation.eagleshre',
    href: PATHS.eagleshare('/'),
    extrnal: true,
  },
];

const mobileMenuItems = [
  {
    id: 'logo',
    text: 'fe_er_common_layout:EagleRider',
    href: PATHS.home(),
    src: '/imgs/logo.webp',
  },
  {
    id: 'rentals',
    text: 'fe_er_common_layout:common.rentals',
    href: PATHS.motorcycleRental(),
    submenu: {
      heading: 'fe_er_common_layout:common.top_motorcycle_rentals',
      items: [], // Fetched from /layout
    },
  },
  {
    id: 'packages_and_tours',
    text: 'fe_er_common_layout:navigation.packages_tours',
    href: PATHS.motorcycleTours.index(),
    submenu: {
      heading: null,
      items: [
        [
          {
            text: 'fe_er_common_layout:common.guided_motorcycle_tours',
            href: PATHS.tour.guidedTourIndex(),
          },
          {
            text: 'fe_er_common_layout:self_motorcycle_tours',
            href: PATHS.tour.selfTourIndex(),
          },
        ],
        [
          {
            text: 'fe_er_common_layout:navigation.all_tours',
            href: PATHS.motorcycleTours.index(),
          },
          {
            text: 'fe_er_common_layout:navigation.see_popular_routes',
            href: PATHS.motorcycleTrips.index(),
          },
        ],
        [
          {
            id: 'packages_and_tours_custom_trip',
            text: 'fe_er_common_layout:navigation.custom_trips',
            href: { url: '/javascript:;' },
          },
        ],
      ],
    },
  },
  {
    id: 'off-road',
    text: 'fe_er_common_layout:navigation.off_road',
    href: PATHS.guidedOffroadTour.index(),
    supText: 'fe_er_common_layout:navigation.new_item',
  },
  {
    id: 'deals',
    text: 'fe_er_common_layout:navigation.deals',
    href: PATHS.motorcycleDeals.index(),
  },
  {
    id: 'events',
    text: 'fe_er_common_layout:navigation.events',
    href: PATHS.motorcycleEvents.index(),
  },
  {
    id: 'locations',
    text: 'fe_er_common_layout:navigation.locations',
    href: PATHS.locations(),
  },
  {
    id: 'check_in',
    text: 'fe_er_common_layout:navigation.check_in',
    href: PATHS.checkIn(),
  },
  {
    id: 'eagleshare',
    text: 'fe_er_common_layout:navigation.eagleshre',
    href: PATHS.eagleshare('/'),
    extrnal: true,
  },
];
/** *
 * Custom hook instead of static arrays because we have data that is fetched dynamically
 * from the API and to separate this logic from the components
 ** */
const useHeaderMenuItems = (forMobile) => {
  const { appState, showMembership } = useSelector((state) => state.globalData);
  const appNavigator = useAppNavigator();
  const isCrawler = useSelector((state) => state.globalData.isCrawler);

  const adjustMenuItems = () => {
    const currentMenuItems = forMobile ? mobileMenuItems : desktopMenuItems;
    const rentals = currentMenuItems.find((item) => item.id === 'rentals');
    const rentalsIndex = currentMenuItems.findIndex((c) => c.id === 'rentals');
    const esIndex = currentMenuItems.findIndex((c) => c.id === 'eagleshare');
    currentMenuItems[esIndex] = {
      id: 'eagleshare',
      text: 'fe_er_common_layout:navigation.eagleshre',
      href: PATHS.eagleshare('/', appNavigator.locale),
      extrnal: true,
    };
    let newItems = currentMenuItems.slice();
    if (
      appState
      && appState.featured_rentals
      && appState.featured_rentals.header
    ) {
      const outerArray = []; // It will be like [[...,...],[...,...],[...,...]]
      for (let i = 0; i < appState.featured_rentals.header.length; i += 2) {
        let innerArray = [appState.featured_rentals.header[i]];
        if (i + 1 < appState.featured_rentals.header.length) {
          innerArray.push(appState.featured_rentals.header[i + 1]);
        }
        innerArray = innerArray.map((rental) => ({
          text: `fe_er_common_layout:rentals.${rental.translation_key}_rentals`,
          href: PATHS.geoSlug(rental.slug),
          is_harley_davidson: rental.is_harley_davidson,
          translate: false,
        }));
        outerArray.push(innerArray);
      }

      newItems = [
        ...newItems.slice(forMobile ? 1 : 0, rentalsIndex),
        {
          // Add rentals submenu
          ...rentals,
          translate: false,
          submenu: {
            ...rentals.submenu,
            items: [...outerArray],
          },
        },
        ...newItems.slice(rentalsIndex + 1),
      ];
    }
    if (showMembership) {
      const prevItem = forMobile
        ? newItems.findIndex((c) => c.id === 'packages_and_tours') + 2
        : newItems.length;
      newItems = [
        // Add membership item after locations
        ...newItems.slice(0, prevItem),
        {
          id: 'membership',
          text: 'fe_er_common_layout:navigation.membership',
          href: PATHS.motorcycleClub.index(),
        },
        ...newItems.slice(prevItem),
      ];
    }
    if (
      forMobile
      && appState.settings
      && appState.settings.show_rental_management_link
    ) {
      newItems.push({
        id: 'rentalManagement',
        text: 'fe_er_common_layout:navigation.rental_management',
        href: PATHS.agent(),
      });
    }
    return newItems;
  };
  const initMenuItems = () => {
    if (!appState) return forMobile ? mobileMenuItems : desktopMenuItems;
    return adjustMenuItems();
  };
  const [menuItems, setMenuItems] = useState(initMenuItems());
  useEffect(() => {
    if (isCrawler) return;
    if (appState) setMenuItems(adjustMenuItems());
  }, [appState, appNavigator.locale]);

  return menuItems;
};

export default useHeaderMenuItems;
