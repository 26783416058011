import { SLUGS_TYPES } from 'lib/utils/CONSTANTS';

export const PICKUP_LOCATION = 'pickup_location';
export const DROPOFF_LOCATION = 'dropoff_location';

export const FILTERS = {
  VEHICLE_MODELS: 'vehicle_models',
  VEHICLE_MAKES: 'vehicle_makes',
  VEHICLE_TYPES: 'vehicle_types',
};

export const PLACE_TYPES = {
  LOCATION: SLUGS_TYPES.location,
  AIRPORT: 'airport',
  REGION: 'region',
  COUNTRY: 'country',
  GEOGRAPHY: 'geography',
  zipcode: 'zipcode',
  MARKET: 'market',
};
export const placeSlugTypes = [...Object.values(PLACE_TYPES)];

export const geoPageSlugTypes = [
  SLUGS_TYPES.vehicle_model,
  SLUGS_TYPES.vehicle_make,
  SLUGS_TYPES.vehicle_type,
  ...placeSlugTypes,
];

export const FE_GEO_URL = 'fe_geo_url';
export const FE_GEO_URL_ORIGIN = 'fe_geo_url_origin';
