import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImgBox } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .AAMContainer': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  '& .AAMImageText': {
    alignItems: 'center',
  },
  '& .AAMTitle': {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.secondary.main,
    lineHeight: theme.spacing(2),
  },
  '& .AAMDesc': {
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.grey[300],
    lineHeight: theme.spacing(2),
  },
  '& .AAMGetBtn': {
    color: theme.palette.common.white,
    backgroundColor: '#1479F0',
    borderRadius: theme.typography.pxToRem(100),
    padding: theme.spacing(0.25, 1),
    minWidth: 'auto',
    '&:hover': {
      filter: 'brightness(93%)',
    },
  },
  '& .AAMImg': {
    padding: theme.typography.pxToRem(8),
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: theme.typography.pxToRem(11),
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  '&.IBimgbox': {
    [theme.breakpoints.down('lg')]: {
      height: theme.spacing(3.25),
      width: theme.spacing(4.125),
    },
  },
}));
export default StyledBox;
