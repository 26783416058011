export const osTypes = {
  android: 'Android',
  ios: 'iOS',
  other: 'other',
};

export const detectDeviceOS = () => {
  if (typeof window === 'undefined') return null;
  let os = osTypes.other;
  const { userAgent } = window.navigator;
  if (/android/i.test(userAgent)) {
    os = osTypes.android;
  } else if (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  ) {
    os = osTypes.ios;
  }
  return os;
};
