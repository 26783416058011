/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loadingSpinner: false,
  snackbar: {
    open: false,
    severity: 'success',
    message: '',
    duration: 4000,
    messageComponent: null,
  },
  error: {
    statusCode: false,
    title: '',
    subtitle: '',
  },
  shouldRenderPage: false,
  supressNavigationLoader: false,
  shouldRenderAiBot: false,
};
export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loadingSpinner = action.payload;
    },
    setSnackbar: (state, action) => {
      state.snackbar = { ...state.snackbar, ...action.payload };
    },
    setError: (state, action) => {
      state.error = { ...state.error, ...action.payload };
    },
    setShouldRenderPage: (state, action) => {
      state.shouldRenderPage = action.payload;
    },
    setSupressNavigationLoader: (state, action) => {
      state.supressNavigationLoader = action.payload;
    },
    setRenderAiBot: (state, action) => {
      state.shouldRenderAiBot = action.payload;
    },
    resetUISlice: () => initialState,
  },
});

export const {
  setLoading,
  setSnackbar,
  setError,
  setShouldRenderPage,
  setSupressNavigationLoader,
  resetUISlice,
  setRenderAiBot,
} = uiSlice.actions;

export default uiSlice.reducer;
